<template>
    <div class="editClss">
        <van-nav-bar :title="substitute ? '代填日报' : '填写日报'" left-text="返回" @click-left="back" fixed left-arrow />

        <van-form class="login_form" ref="loginForm">
            <div>
                <van-field readonly clickable right-icon="arrow"  v-if="substitute"
                    :value="fillingAgent.name" :label="'代填人员'" placeholder="请选择代填人员"
                    @click="auditorShow = true">
                    <template #input>
                        <span v-if="user.userNameNeedTranslate == '1'"><TranslationOpenDataText type='userName'
                                :openid='fillingAgent.name'></TranslationOpenDataText></span>
                        <span v-else>{{ fillingAgent.name }}</span>
                    </template>
                </van-field>

                <van-cell >
                    <van-field style="padding:0px" readonly clickable name="datetimePicker" :value="form.createDate" label="时间选择"
                        placeholder="点击选择时间" @click="showPicker = true" :rules="rules.createDate" >
                    </van-field>
                    <template #right-icon>
                        <!--先开放几家，试一下-->
                        <span style="color:#20a0ff;" @click="getAIReport()" v-if="!hasWrittenReport && (user.companyId==10||user.companyId==7||user.companyId==7030)">智能填报</span>
                    </template>
                </van-cell>
                
                
                <van-cell>
                    <template #default>
                        <div class="attendanceRecord">
                            <template v-if="user.timeType.syncDingding == 1 || user.timeType.syncCorpwxTime == 1">
                                <span>打卡:</span>
                                <span v-if="!report.time">暂无考勤记录</span>
                                <span v-else>{{ report.time.startTime }}-{{ report.time.endTime }}, 工作{{
                                    report.time.workHours }}h
                                    <span v-if="report.time.askLeaveTime">|&nbsp;请假{{ report.time.askLeaveTime
                                    }}h</span></span>
                            </template>
                            <span style="margin-left:5px">总填报：</span>
                            <span>{{ totalReportHours }}h</span>
                        </div>
                    </template>
                    <template #right-icon>
                        <van-button icon="replay" native-type="button" type="default" size="mini"
                            style="height:0.6rem;padding:0 0.16667rem;" :loading="cardRefLoading" loading-size="0.26667rem"
                            @click.stop.native="cardtimeRefresh(form.createDate)"
                            v-if="user.timeType.syncCorpwxTime == 1 && (user.timeType.syncDingding == 1 || user.timeType.syncCorpwxTime == 1)"></van-button>
                    </template>
                </van-cell>
                <!-- <div v-if="report.time" class="attendanceRecord">
                    <i class="iconfont firerock-icondakajilu" style="font-size:0.35rem;margin-right:0.12rem"></i>
                    <span v-if="user.timeType.syncDingding==1&&!report.time">暂无考勤记录</span>
                    <span v-else>{{report.time.startTime}}-{{report.time.endTime}}, {{report.time.workHours}}小时</span>
                </div> -->
            </div>
            <van-popup v-model="showPicker" position="bottom">
                <van-datetime-picker v-model="currentDate" type="date" :min-date="minDate" :max-date="maxDate"
                    @confirm="changeTime" @cancel="showPicker = false" />
            </van-popup>
            <van-cell title="总时长(h)" v-if="reportTimeType.type == 3">
                <template>
                    <van-stepper :disabled="(!canEdit || user.timeType.lockWorktime == 1) && !isWeekend"
                        v-model="reportTimeType.allday" @change="changeAllTime" min="0.5" max="12" step="0.5"
                        :decimal-length="1" />
                </template>
            </van-cell>

            <!-- <van-cell title="待分配时长" :value="report.time + 'h'" size="large"></van-cell> -->

            <div class="form_domains" v-for="(item, index) in form.domains" :key="item.id">
                <div style="float:right;margin-top:10px;margin-right:10px;">
                    <!-- <van-tag v-if="canEdit&&item.projectName.length>0" color="#fff"
                @click="copyProject(index)" style="border: 1px solid #20a0ff;padding:5px;"
                 icon="plus" type="default" ><span style="color:#666;padding: 0 5px;">复制项目</span></van-tag> -->

                    <van-tag v-if="(index > 0 || form.domains.length > 1) && item.canEdit" color="#fff"
                        @click="delPro(index)" style="border: 1px solid #ff0000;padding:5px;margin-left:10px;" icon="plus"
                        type="default"><span style="color:#666;padding: 0 5px;">删除</span></van-tag>
                </div>
                <!-- <van-icon v-if="index>0&&canEdit" class="form_del" name="delete" @click="delPro(index)" /> -->
                <van-cell-group :title="(user.companyId == 781 ? '任务' : '项目') + (index + 1)">
                    <van-field readonly name="userReportDeptName" v-if="user.timeType.userWithMultiDept == 1 && userReportDeptList.length > 0"
                        :value="item.userReportDeptName" :label="'填报部门'" placeholder="请选择部门" :disabled="!item.canEdit" @click="selectDeptPopup(index, item)">
                        <template #input>
                            <TranslationOpenDataText type='departmentName' :openid='item.userReportDeptName'></TranslationOpenDataText>
                        </template>
                    </van-field>

                    <!-- <div>请选择投入项目</div> -->
                    <van-field readonly name="projectId" clickable :value="item.projectName" type="textarea" autosize
                        :label="user.companyId == 781 ? '工作任务' : '投入项目'" :disabled="!item.canEdit" rows="1"
                        :placeholder="user.companyId == 781 ? '请选择工作任务' : '请选择项目'" @click="clickPicker(index, item)"
                        :rules="[{ required: true, message: user.companyId == 781 ? '请选择任务' : '请选择项目' }]" />
                    <!--昱众不显示子项目-->
                    <van-field readonly name="subProjectId" :disabled="!item.canEdit"
                        v-if="user.companyId != yuzhongCompId && item.subProjectList != null && item.subProjectList.length > 0 && user.timeType.mainProjectState != 1"
                        clickable :value="item.subProjectName" label="子项目" placeholder="请选择子项目"
                        @click="clickPickSubProject(index, item)" />
                    <van-popup v-model="item.showPickerSubProject" position="bottom">
                        <van-picker show-toolbar :columns="item.subProjectList" value-key="name" @confirm="choseSubProject"
                            @cancel="item.showPickerSubProject = false; $forceUpdate();" />
                    </van-popup>
                    <van-field readonly name="extraField1" v-if="user.companyId == yuzhongCompId" clickable
                        :value="item.extraField1Name" label="角色选择" placeholder="请选择担任的角色" :disabled="!item.canEdit"
                        @click="clickPickProjectRole(index, item)" />
                    <van-popup v-model="item.showPickerRole" position="bottom">
                        <van-picker show-toolbar :columns="roleList" value-key="label" @confirm="choseRole"
                            @cancel="item.showPickerRole = false; $forceUpdate();" />
                    </van-popup>

                    <!--任务分组 -->
                    <van-field readonly name="groupId" :disabled="!item.canEdit"
                        v-if="user.company.packageProject == 1 && item.taskGroups != null && item.taskGroups.length > 0 && (user.company.nonProjectSimple==0 || (user.company.nonProjectSimple==1&& (item.projectId && projectss.filter(p => p.id == item.projectId)[0].isPublic!=1)))"
                        clickable :rules="[{ required: user.companyId == 3092 ? true : false, message: '请选择任务分组' }]"
                        :value="item.groupName" label="任务分组" placeholder="请选择任务分组"
                        @click="clickPickTaskGroup(index, item)" />
                    <!-- <van-popup v-model="item.showPickerTaskGroup" position="bottom">
                        <van-picker show-toolbar :columns="item.taskGroups" value-key="name" @confirm="choseTaskGroup"
                            @cancel="item.showPickerTaskGroup = false; $forceUpdate();" />
                    </van-popup> -->

                    <van-popup v-model="item.showPickerTaskGroup" position="bottom" :style="{ height: '80vh' }" @click-overlay="item.showPickerTaskGroup = false;$forceUpdate();">
                        <div class="groupingSelection">
                            <div v-for="(taskItem, taskIndex) in item.taskGroups" :key="taskIndex" class="groupingSelectionItem" @click="choseTaskGroup(taskItem, taskIndex)">
                                {{ taskItem.name }}
                            </div>
                        </div>
                    </van-popup>

                    <!--工作职责-->
                    <van-field readonly name="extraField2" v-if="user.companyId == yuzhongCompId" clickable
                        :value="item.extraField2Name" label="工作职责" placeholder="请选择工作职责" :disabled="!item.canEdit"
                        @click="clickPickRespon(index, item)" />
                    <van-popup v-model="item.showPickerRespon" position="bottom">
                        <van-picker show-toolbar :columns="item.filteredRespList" value-key="jobRespon"
                            @confirm="onResponseChosen" @cancel="item.showPickerRespon = false; $forceUpdate();" />
                    </van-popup>
                    <!--工作内容-->
                    <van-field readonly name="extraField3" v-if="user.companyId == yuzhongCompId" clickable
                        :value="item.extraField3Name" label="工作内容" placeholder="请选择工作内容" :disabled="!item.canEdit"
                        @click="clickPickWorkContent(index, item)" />
                    <van-popup v-model="item.showPickerWorkContent" position="bottom">
                        <van-picker show-toolbar :columns="item.workContentList" value-key="workContext"
                            @confirm="choseWorkContent" @cancel="item.showPickerWorkContent = false; $forceUpdate();" />
                    </van-popup>
                    <!--任务阶段 -->
                    <van-field readonly name="stage" :disabled="!item.canEdit"
                        v-if="user.companyId != yuzhongCompId && user.company.packageProject == 1 && !user.timeType.hideStages && item.stages != null && item.stages.length > 0"
                        clickable :value="item.stage" label="投入阶段" placeholder="请选择投入阶段"
                        @click="clickPickStage(index, item)" />
                    <van-popup v-model="item.showPickerStage" position="bottom">
                        <van-picker show-toolbar :columns="item.stages" value-key="stagesName" @confirm="choseStage"
                            @cancel="item.showPickerStage = false; $forceUpdate();" />
                    </van-popup>
                    <!-- 预算来源 -->
                    <van-field readonly name="basecostId" :disabled="!item.canEdit"
                        v-if="user.company.packageProject == 1 && reportBasecostList && reportBasecostList.length > 0"
                        :value="item.basecostName" label="预算来源" placeholder="请选择预算来源"
                        @click="clickPickCostId(index, item)" />
                    <van-popup v-model="item.showPickerCostId" position="bottom">
                        <van-picker show-toolbar :columns="reportBasecostList" value-key="name" @confirm="choseCostId"
                            @cancel="item.showPickerCostId = false; $forceUpdate();" />
                    </van-popup>
                    <!-- 审核人 -->
                    <template v-if="user.timeType.reportAuditType != 3">
                        <van-field readonly name="projectAuditorId" :disabled="!item.canEdit"
                            v-if="item.auditUserList != null && item.auditUserList.length > 0" clickable
                            :value="item.projectAuditorName" :label="user.companyId == 781 ? '审核人' : '项目审核人'"
                            placeholder="请选择审核人" @click="clickPickAuditor(index, item)">
                            <template #input>
                                <span v-if="user.userNameNeedTranslate == '1'"><TranslationOpenDataText type='userName'
                                        :openid='item.projectAuditorName'></TranslationOpenDataText></span>
                                <span v-else>{{ item.projectAuditorName }}</span>
                            </template>
                        </van-field>
                        <van-popup v-model="item.showPickerAuditor" position="bottom">
                            <van-picker show-toolbar :columns="item.auditUserList" value-key="auditorName"
                                @confirm="choseAuditor" @cancel="item.showPickerAuditor = false; $forceUpdate();">
                                <template #option="item">
                                    <span v-if="user.userNameNeedTranslate == '1'"><TranslationOpenDataText type='userName'
                                            :openid='item.auditorName'></TranslationOpenDataText></span>
                                    <span v-else>{{ item.auditorName }}</span>
                                </template>
                            </van-picker>
                        </van-popup>
                    </template>

                    <!-- 自选审批人 -->
                    <template v-if="user.timeType.reportAuditType == 3">
                        <van-field readonly clickable label="第一审核人" :disabled="!item.canEdit" @click="auditorClick(index, 'auditorFirst')">
                            <template #input>
                                <span v-if="!item.auditorFirst"></span>
                                <span v-else-if="user.userNameNeedTranslate == '1'"><TranslationOpenDataText type='userName'
                                        :openid='item.auditorFirst.name'></TranslationOpenDataText></span>
                                <span v-else>{{ item.auditorFirst.name }}</span>
                            </template>
                        </van-field>
                        <van-field readonly clickable label="第二审核人" :disabled="!item.canEdit" @click="auditorClick(index, 'auditorSec')"
                            v-if="user.timeType.auditLevel > 1">
                            <template #input>
                                <span v-if="!item.auditorSec"></span>
                                <span v-else-if="user.userNameNeedTranslate == '1'"><TranslationOpenDataText type='userName'
                                        :openid='item.auditorSec.name'></TranslationOpenDataText></span>
                                <span v-else>{{ item.auditorSec.name }}</span>
                            </template>
                        </van-field>
                        <van-field readonly clickable label="第三审核人" :disabled="!item.canEdit" @click="auditorClick(index, 'auditorThird')"
                            v-if="user.timeType.auditLevel > 2">
                            <template #input>
                                <span v-if="!item.auditorThird"></span>
                                <span v-else-if="user.userNameNeedTranslate == '1'"><TranslationOpenDataText type='userName'
                                        :openid='item.auditorThird.name'></TranslationOpenDataText></span>
                                <span v-else>{{ item.auditorThird.name }}</span>
                            </template>
                        </van-field>
                        <van-field readonly clickable label="抄送人" @click="auditorClick(index, 'ccUserid')">
                            <template #input>
                                <span v-if="!item.ccUserid"></span>
                                <span v-else-if="user.userNameNeedTranslate == '1'"><TranslationOpenDataText type='userName'
                                        :openid='item.ccUserid.name'></TranslationOpenDataText></span>
                                <span v-else>{{ item.ccUserid.name }}</span>
                            </template>
                        </van-field>
                    </template>

                    <!-- 选人 -->
                    <van-popup v-model="auditorShow" position="bottom">
                        <van-search v-model="auditor.searchText" placeholder="输入员工姓名搜索" @search="onSearch"
                            v-if="user.userNameNeedTranslate != '1'"></van-search>
                        <div style="minHeight:300px;">
                            <van-radio-group v-model="auditor.item">
                                <van-radio v-for="uitem in auditor.searchList" :key="uitem.id" :name="uitem"
                                    style="padding:10px">
                                    <span v-if="user.userNameNeedTranslate == '1'"><TranslationOpenDataText type='userName'
                                            :openid='uitem.name'></TranslationOpenDataText></span>
                                    <span v-else>{{ uitem.name ? uitem.name : '' }}</span>
                                </van-radio>
                            </van-radio-group>
                            <van-button style="width:100%;position: -webkit-sticky;position: sticky;bottom: 0;"
                                @click="searchAuditor()">确定</van-button>
                        </div>
                    </van-popup>

                    <!-- 相关维度 -->
                    <van-field :value="item.weiduName" v-if="item.projectId && user.timeType.customDegreeActive == 1"
                        readonly name="id" clickable :label="user.timeType.customDegreeName" placeholder="请选择"
                        @click="clickPickers(index, item)" :disabled="!item.canEdit" />

                    <!-- <van-popup v-model="item.showPickDegree" position="bottom">
                        <van-picker show-toolbar :columns="item.wuduList" value-key="name" @confirm="choseProjects"
                            @cancel="item.showPickDegree = false; $forceUpdate()" />
                    </van-popup> -->

                    <van-popup v-model="item.showPickDegree" position="bottom">
                        <div class="chooseSomeone">
                            <div class="chooseSomeoneFlex1">
                                <!-- 复选 -->
                                <van-radio-group v-model="item.radioPickDegree" v-if="!user.timeType.customDegreeMultiple">
                                    <van-radio v-for="uitem in item.wuduList" :key="uitem.id" :name="uitem" style="padding:10px">
                                        {{ uitem.name ? uitem.name : '' }}
                                    </van-radio>
                                </van-radio-group>
                                <!-- 多选 -->
                                <van-checkbox-group v-model="item.radioPickDegree" v-else>
                                    <van-checkbox v-for="uitem in item.wuduList" :key="uitem.id" :name="uitem" style="padding:10px">
                                        {{ uitem.name ? uitem.name : '' }}
                                    </van-checkbox>
                                </van-checkbox-group>
                            </div>
                            <van-button @click="choseProjects(item.radioPickDegree)">
                                确定
                            </van-button>
                        </div>
                    </van-popup>

                    <!-- 自定义数值 -->
                    <van-field v-if="user.timeType.customDataActive == 1" type="number" :disabled="!item.canEdit"
                        v-model="item.customData" :label="user.timeType.customDataName" placeholder="请输入数字"
                        :rules="user.timeType.customDataStatus == 1 ? [{ required: true, message: '请输入' + user.timeType.customDataName }] : []"></van-field>
                    <!-- 自定义文本 -->
                    <van-field v-if="user.timeType.customTextActive == 1" :disabled="!item.canEdit"
                        v-model="item.customText" :label="user.timeType.customTextName" placeholder="请输入" maxlength="1000"
                        :rules="user.timeType.customTextStatus ? [{ required: true, message: '请选择' + user.timeType.customTextName }] : []"
                        rows="2" type="textarea" show-word-limit autosize></van-field>

                    <van-cell title="专业进度" v-if="user.company.packageEngineering == 1">
                    </van-cell>
                    <van-field :disabled="!item.canEdit" type="number" :name="'progress_' + pItem.professionId"
                        input-align="right" v-for="pItem in item.professionProgress" :key="pItem.professionId"
                        :label="'--    ' + pItem.professionName">
                        <template slot="input">
                            <van-stepper v-model="pItem.progress" integer min="0" max="100" />%
                        </template>
                    </van-field>
                    <van-field v-if="user.company.packageProject == 1 && !user.timeType.hideTask && (user.company.nonProjectSimple==0 || (user.company.nonProjectSimple==1&& (item.projectId && projectss.filter(p => p.id == item.projectId)[0].isPublic!=1)))" readonly name="taskId" :value="item.taskName"
                        :rules="[{ required: reportTimeType.taskRequired? true : false, message: '请选择任务/里程碑' }]" label="任务/里程碑" :disabled="!item.canEdit"
                        placeholder="请选择任务/里程碑" @click="clickPickerTask(index, item)"></van-field>
                    <van-field v-if="user.companyId == 3092" readonly name="sapServiceId" :value="item.sapServiceName"
                        :rules="[{ required: true, message: '请选择服务' }]" label="服务" placeholder="请选择服务"
                        @click="clickPickerService(index, item)"></van-field>
                    <van-field name="taskFinish" label="是否已完成" v-if="item.taskId != null">
                        <template #input>
                            <van-checkbox :disabled="!item.canEdit" v-model="item.taskFinish"
                                shape="square">已完成</van-checkbox>
                        </template>
                    </van-field>
                    <van-popup v-model="item.showPickerTask" position="bottom">
                        <van-picker show-toolbar :columns="item.taskList" value-key="taskName" @confirm="choseTask"
                            @cancel="item.showPickerTask = false; $forceUpdate()" />
                    </van-popup>

                    <van-popup v-model="item.showPickerService" position="bottom">
                        <van-picker show-toolbar :columns="item.serviceList" value-key="serviceName" @confirm="choseService"
                            @cancel="item.showPickerService = false; $forceUpdate()" />
                    </van-popup>
                    <!-- <van-field readonly clickable class="form_input" :value="item.workingTime" name="workingTime" label="工作时长" placeholder="请输入工作时长（单位：小时）"
                    :rules="[{ required: true, message: '请输入工作时长（单位：小时）' }]" @touchstart.native.stop="showNumberKey = true"/>
                    <van-number-keyboard v-model="item.workingTime" :show="showNumberKey" close-button-text="完成" extra-key="." :maxlength="4" @blur="showNumberKey = false" /> -->

                    <!-- 常规选择时间的方式 -->
                    <!-- 全天上下午模式 -->
                    <div v-if="reportTimeType.multiWorktime == 0">
                        <van-field v-if="reportTimeType.type < 2" readonly clickable :disabled="!item.canEdit"
                            :value="reportTimeType.type == 0 ? item.label : (parseFloat(item.workingTime).toFixed(1) + 'h')"
                            label="工作时长" placeholder="请选择工作时长(小时)" @click="clickTimePicker(index, item)"
                            :rules="[{ required: true, message: '请选择工作时长' }]" />
                        <van-popup v-model="showPickerTime" position="bottom">
                            <van-picker show-toolbar :columns="timeType" value-key="label" @confirm="choseTimePick"
                                @cancel="showPickerTime = false" />
                        </van-popup>

                        <!-- 选择数字时间长度模式 -->
                        <van-popup v-model="showPickerHours" position="bottom">
                            <van-picker show-toolbar :columns="timeRange" :default-index="15" @confirm="choseTimePick"
                                @cancel="showPickerHours = false" />
                        </van-popup>

                        <!-- 时间段选择模式 -->
                        <van-field readonly v-if="reportTimeType.type == 2" clickable name="datetimePicker"
                            :value="item.startTime" label="开始时间" placeholder="点击选择时间"
                            @click="item.canEdit ? showStartTime = true : '', dailyIndex = index" :disabled="!item.canEdit"
                            :rules="[{ required: true, message: '请选择开始时间' }]" />
                        <van-popup v-model="showStartTime" position="bottom">
                            <van-datetime-picker v-model="startTime" type="time" @confirm="confirmTime(item, 0);"
                                @cancel="showStartTime = false" :min-hour="0" :max-hour="23" />
                            <!-- :filter="filter" 原本这个属性在里面 -->
                        </van-popup>
                        <van-field v-if="reportTimeType.type == 2" readonly clickable name="datetimePicker"
                            :value="item.endTime" label="结束时间" placeholder="点击选择时间"
                            @click="item.canEdit ? showEndTime = true : '', dailyIndex = index" :disabled="!item.canEdit"
                            :rules="[{ required: true, message: '请选择结束时间' }]" />
                        <van-popup v-model="showEndTime" position="bottom">
                            <van-datetime-picker v-model="endTime" type="time" :min-hour="0" :max-hour="23"
                                @confirm="confirmTime(item, 1)" @cancel="showEndTime = false" />
                            <!-- :filter="filter" 原本这个属性在里面 -->
                        </van-popup>
                        <van-cell v-if="reportTimeType.type == 3">
                            <template>
                                <div>
                                    <span>用时占比</span>
                                    <van-slider :disabled="!item.canEdit" :min="0" :step="5"
                                        style="width:120px;display:inline-block;margin-left:50px;" v-model="item.progress"
                                        :value="100"
                                        @change="item.workingTime = (reportTimeType.allday * item.progress / 100).toFixed(1), setTotalReportHours()">
                                        <template #button>
                                            <div class="custom-button">{{ item.progress }}%</div>
                                        </template>
                                    </van-slider>
                                    <span style="margin-left:10px;float:right;">{{ item.workingTime }}小时</span>
                                </div>
                            </template>
                        </van-cell>
                        <van-field class="form_input" :disabled="!item.canEdit" v-model="item.content" name="content"
                            type="textarea" :label="user.companyId == 781 ? '具体内容与结果' : '工作事项'" placeholder="请输入"
                            :rules="user.timeType.workContentState == 1 ? [{ required: true, message: '请填写工作事项' }] : null"
                            rows="3" autosize />
                    </div>

                    <!-- 多个时间和工作事项的选择方式 -->
                    <div v-if="reportTimeType.multiWorktime == 1">
                        <div v-for="(timeItem, tindex) in item.worktimeList" :key="tindex"
                            style="position:relative;border:#ccc 0.5px solid;margin:7px;">
                            <van-tag v-if="tindex > 0 && item.canEdit"
                                style="position:absolute; right:-7px;top:-7px;z-index:10;"
                                @click="removeTimeItem(item, tindex)">X</van-tag>

                            <!-- 时间段选择模式 -->
                            <van-field readonly v-if="reportTimeType.type == 2" :clickable="item.canEdit"
                                name="datetimePicker" :value="timeItem.startTime" label="开始时间" placeholder="点击选择时间"
                                :rules="[{ required: true, message: '必填项' }]"
                                @click="item.canEdit ? showStartDialog(timeItem) : ''" />

                            <van-field v-if="reportTimeType.type == 2" readonly :clickable="canEdit" name="datetimePicker"
                                :value="timeItem.endTime" label="结束时间" placeholder="点击选择时间"
                                :rules="[{ required: true, message: '必填项' }]"
                                @click="item.canEdit ? showEndDialog(timeItem) : ''" />

                            <van-field class="form_input" :disabled="!item.canEdit"
                                style="color:#333;-webkit-text-fill-color:#646566;" v-model="timeItem.content"
                                name="content" type="textarea" label="工作事项" placeholder="请输入工作事项"
                                :rules="user.timeType.workContentState == 1 ? [{ required: true, message: '请填写工作事项' }] : null"
                                rows="1" autosize />
                        </div>
                        <!--时间选择器 , 做统一处理，不能放到循环里，不然会有多个公用showStartTime,最后一个会现在最上层UI,导致BUG -->
                        <van-popup v-model="showWorkStartTime" position="bottom">
                            <van-datetime-picker v-model="startTime" type="time" @confirm="confirmWorkTime(0);"
                                @cancel="showWorkStartTime = false" :min-hour="0" :max-hour="23" />
                            <!-- :filter="filter" 原本这个属性在里面 -->
                        </van-popup>
                        <van-popup v-model="showWorkEndTime" position="bottom">
                            <van-datetime-picker v-model="endTime" type="time" :min-hour="0" :max-hour="23"
                                @confirm="confirmWorkTime(1)" @cancel="showWorkEndTime = false" />
                            <!-- :filter="filter" 原本这个属性在里面 -->
                        </van-popup>
                    </div>

                    <div style="width:100%;" v-if="item.canEdit && reportTimeType.multiWorktime == 1">
                        <van-tag style="text-align:center;padding:5px;margin-left:15px;border: 1px solid #20a0ff;"
                            :disabled="!item.canEdit" @click="addNewWorktime(index, item)" icon="plus" color="#ffffff"><span
                                style="color:#999;text-align:center;padding: 0 5px;"> 添加工时 </span></van-tag>
                    </div>

                    <div class="overtime"
                        v-if="((user.timeType.fillOvertime || (isWeekend && user.timeType.lockWorktime != 1)) || (isCorpWX && canEdit))">
                        <div class="overTimeClas"
                            v-if="user.timeType.fillOvertime || (isWeekend && user.timeType.lockWorktime != 1)">
                            <van-checkbox :disabled="!item.canEdit" v-model="item.isOvertime"
                                style="width: 4.3rem;">含加班</van-checkbox>
                            <van-field v-model="item.overtimeHours" type="number"
                                :disabled="!item.canEdit || item.isOvertime == null || item.isOvertime == 0 || !item.isOvertime"
                                placeholder="请输入加班时长" style="width: 5.2rem" @input="$forceUpdate()"></van-field>
                            <span :class="item.canEdit ? 'overListTime' : 'overListTime hoveOver'">小时</span>
                        </div>
                        <van-tag style="position:absolute;right:10px;" v-if="user.companyId != 4281 && isCorpWX && item.canEdit" type="primary"
                            size="large" @click="takePhoto(index)">拍照上传</van-tag>
                        <!-- <van-tag style="position:absolute;right:10px;" type="primary" size="large" @click="takePhoto(index)">拍照上传</van-tag> -->
                        <!-- <van-tag style="position:absolute;right:10px;" type="primary" size="large" @click="takePhoto(index)">拍照上传</van-tag> -->
                    </div>
                    <div style="padding:5px;text-align:center;" v-if="!isIOSystem">
                        <span v-for="(p, index) in item.pics" :key="p" style="margin-right:15px;">
                            <div class="imgList">
                                <van-icon v-if="item.canEdit && user.companyId == 7" size="20" name="clear"
                                    @click="deleteImg(item, index)" class="imgList_i" />
                                <img :src="p" style="width:100px; height:100px;" @click="showLargeImg(item.pics, index)" />
                            </div>
                        </span>
                    </div>
                    <div style="padding:5px;text-align:center;" v-if="isIOSystem">
                        <span v-for="(p, index) in item.iospics" :key="p" style="margin-right:15px;">
                            <div class="imgList">
                                <van-icon v-if="item.canEdit && user.companyId == 7" size="20" name="clear"
                                    @click="deleteImg(item, index)" class="imgList_i" />
                                <img :src="p" style="width:100px; height:100px;"
                                    @click="showLargeImg(item.iospics, index)" />
                            </div>
                        </span>
                    </div>
                    <van-popup v-model="imgShow" position="bottom" closeable>
                        <van-swipe class="my-swipe" indicator-color="white">
                            <van-swipe-item v-for="(picItem, index) in tmpPics" :key="index">
                                <img :src="picItem" style="width:100%;" />
                            </van-swipe-item>
                        </van-swipe>
                    </van-popup>
                </van-cell-group>

            </div>
            <div style="text-align:center;" v-if="canEdit">
                <van-tag size="large"
                    style="text-align:center;margin:10px;padding:12px;margin-bottom:120px;border: 1px solid #20a0ff;"
                    @click="addNewPro" icon="plus" color="#ffffff"><span
                        style="color:#999;text-align:center;padding: 0 50px;"> + 新增{{ user.companyId == 781 ? '任务' : '项目' }}
                    </span></van-tag>
                <!-- <van-button id="btn-start-recording" @click="startRecording">录音</van-button>
                <van-button id="btn-stop-recording" @click="stopRecording">停止</van-button>
                <van-button id="btn-play-recording" @click="getRecording">获取文件</van-button> -->
            </div>
            <div class="form_btn" style="position:fixed; bottom:0px;width:100%;">
                <div style="padding-bottom:10px;" v-if="!substitute">
                    <van-button v-if="canEdit" square block type="info" @click="isDraft = 0; submitReport()"
                        native-type="submit" style="width: 50%;float:left;">
                        <div v-if="flgLg">提交</div>
                        <van-loading type="spinner" v-if="!flgLg" />
                    </van-button>
                    <van-button v-if="canEdit" square block type="default" @click="isDraft = 1; submitReport()"
                        native-type="submit" style="width:50%;float:left;">
                        <div v-if="flgLg">暂存</div>
                        <van-loading type="spinner" v-if="!flgLg" />
                    </van-button>
                </div>
                <div style="padding-bottom:10px;" v-if="substitute">
                    <van-button v-if="canEdit" square block type="info" @click="isDraft = 0; submitReport()"
                        native-type="submit">
                        <div v-if="flgLg">提交</div>
                        <van-loading type="spinner" v-if="!flgLg" />
                    </van-button>
                </div>
                <van-button v-if="canDeleteReport" square block type="default" @click="deleteReport" native-type="button"
                    style=""> 删除 </van-button>
                <van-button v-if="canCancelInDialog" block type="default" @click="cancel"> 撤回 </van-button>
                <!-- <div v-if="canEdit&&form.domains.length>0 && form.domains[0].id != null"
                    style="display:block;padding-top:30px;font-size:15px;color:#666;margin:0 auto;text-align:center;padding-bottom:10px;background:#ffffff;" 
                    @click="deleteReport"> 删除 </div> -->
                <!-- <van-button v-if="canEdit" square block type="default" @click="deleteReport" native-type="button" 
                    style="margin-top:10px;"> 删除 </van-button> -->
            </div>
        </van-form>


        <div class="form_tip" v-if="!canEdit && !canCancel"> 已审核无法修改 </div>

        <!-- 选择项目弹窗 -->
        <van-popup v-model="showPickerUserddp" position="bottom" style="height: 84%">
            <div class="popupDiv">
                <!-- <van-search v-model="userName" placeholder="请输入项目名称/编号" @clear="sea()" @blur="sea()" @search="sea()" @input="sea()"/> -->
                <div class="popupSearch">
                    <van-search v-model="userName" shape="round" background="#F4F4F4" placeholder="请输入项目名称/编号"
                        @clear="sea()" @blur="sea()" @search="sea()" @input="sea()" />
                </div>

                <div class="popupCon">
                    <!-- <div class="ryuan" style="color: rgb(185 185 185);">近期选择项目</div>
                    <div v-for="(item, index) in integrationProjectList" :key="index" class="ryuan" @click="fZr(item, index)">
                        <p>{{item.projectName}}</p>
                        <p style="margin-top: 5px;color: #9697B2;">{{item.projectCode}}</p> 
                    </div>

                    <div class="ryuan" style="color: rgb(185 185 185);">全部项目</div>
                    <div v-for="(item, index) in projectss" :key="item.id" class="ryuan" @click="fZr(item, index)">
                        <p>{{item.projectName}}</p>
                        <p style="margin-top: 5px;color: #9697B2;">{{item.projectCode}}</p> 
                    </div> -->

                    <div class="popupTitle">近期选择项目</div>

                    <div v-for="(item, index) in integrationProjectList" :key="index" class="popupItem paddingDiv"
                        @click="fZr(item, index)">
                        <div class="popupItemOne" v-if="item.projectName">{{ item.projectName }}</div>
                        <p class="popupItemTwo" v-if="item.projectCode">{{ item.projectCode }}</p>
                    </div>

                    <div class="popupTitle marginTop">全部项目</div>

                    <div v-for="(item, index) in projectss" :key="item.id" class="popupItem paddingDiv"
                        @click="fZr(item, index)">
                        <div class="popupItemOne" v-if="item.projectName">{{ item.projectName }}</div>
                        <p class="popupItemTwo" v-if="item.projectCode">{{ item.projectCode }}</p>
                    </div>

                    <!-- <div class="popupTitle paddingDiv">全部项目</div> -->
                </div>
            </div>
        </van-popup>

        <van-popup v-model="showSelectDeptPopup" position="bottom" style="height: 84%">
            <div class="popupDiv">
                <div class="popupCon">
                    <div v-for="(item, index) in userReportDeptList" :key="item.value" class="popupItem paddingDiv"
                        @click="selectedDeptId(item, index)">
                        <p class="popupItemOne">
                            <TranslationOpenDataText type='departmentName' :openid='item.departmentName'></TranslationOpenDataText>
                        </p>
                    </div>
                </div>
            </div>
        </van-popup>
    </div>
</template>
<script>
import timetoolVue from '../timetool/timetool.vue';
// Vue.prototype.$wx = wx
// import { Recorder } from '../../assets/record/recorder'
// var recorder = null
export default {
    data() {
        return {
            roleList: [{ value: 1, label: 'CRC&LM' }, { value: 2, label: 'PM' }],
            jobResponseList: [],
            yuzhongCompId: 3385,
            dailyIndex: 0,

            integrationProjectList: [],
            cardRefLoading: false,
            today: '',
            hasWrittenReport: false,
            showPickerStage: false,
            showPickerTaskGroup: false,
            showPickerSubProject: false,
            showPickerCostId: false,
            isDraft: 0,
            showWorkStartTime: false,
            showWorkEndTime: false,
            curWorktime: null,
            isIOSystem: false,
            imgShow: false,
            isCorpWX: false,
            isWX: false,
            showPickerTask: false,
            canCancel: false,
            canDeleteReport: false,
            canCancelInDialog: false,
            canEdit: false,
            showEndTime: false,
            showStartTime: false,
            startTime: '09:00',
            endTime: '18:00',
            nowTime: new Date(),
            showPickerHours: false,
            timeRange: [],
            selectTime: null,
            reportTimeType: {},
            user: JSON.parse(localStorage.userInfo),
            minDate: new Date(2010, 0, 1),
            maxDate: new Date(),
            currentDate: new Date(),
            showPickerTime: false,
            showPicker: false,
            showPickerProject: false,
            showPickDegree: false,
            clickIndex: 0,
            clickTimeIndex: 0,
            showNumberKey: false,
            canClick: 2,
            timeType: [],
            form: {
                createDate: this.format(new Date(new Date()), "yyyy-MM-dd"),
                domains: [{
                    id: null,
                    projectId: "",
                    projectName: "",
                    workingTime: "",
                    content: "",
                    state: 2,
                    multiWorktime: 0,
                    worktimeList: {},
                    degreeId: ''
                    // pics:["https://worktime.ttkuaiban.com/upload/bc4df504fa724e6cab69872e2c1cfb35.png",
                    // "https://worktime.ttkuaiban.com/upload/bc4df504fa724e6cab69872e2c1cfb35.png",
                    // "https://worktime.ttkuaiban.com/upload/bc4df504fa724e6cab69872e2c1cfb35.png",]
                }],
            },
            rules: {
                createDate: [{ required: true, message: '请选择填报日期' }],
            },
            project: [],
            report: "",

            loading: false,
            finished: false,
            // isOvertime: false
            tmpPics: [],
            dateAr: [],
            showPickerUserddp: false, // 选择项目弹窗
            projectss: [],
            proads: [],
            userName: '',
            flgLg: true,
            reportBasecostList: [],
            isWeekend: false,

            auditorShow: false,
            auditor: {
                index: 0,
                auditorIndex: 'auditorFirst',
                searchText: '',
                item: {},
                list: [],
                searchList: []
            },
            // vacationTime: {},
            totalReportHours: 0,
            // leftDataList:[],
            // rightDataList:[],

            substitute: false, // 是否为代填日报
            fillingAgent: {
                name: '',
                id: ''
            }, //  代填人员信息

            userReportDeptList: [], // 可选部门
            showSelectDeptPopup: false
        };
    },

    created() {

    },
    watch: {},
    computed: {
        // totalReportHours(){
        //     let domains = this.form.domains
        //     let hours = 0
        //     for(let i in domains){
        //         if(domains[i].projectId){
        //             // hours += parseFloat(domains[i].workingTime)
        //             if(this.reportTimeType.multiWorktime == 1){
        //                 for(let m in domains[i].worktimeList){
        //                     if(domains[i].worktimeList[m].startTime && domains[i].worktimeList[m].endTime){
        //                         hours += this.getHour(domains[i].worktimeList[m].startTime, domains[i].worktimeList[m].endTime)
        //                     }
        //                 }
        //             }else{
        //                 if(this.user.timeType.type == 2){
        //                     if(domains[i].startTime && domains[i].endTime){
        //                         let selectionTime = this.getHour(domains[i].startTime, domains[i].endTime)
        //                         let subtractedData = 0
        //                         let arr = JSON.parse(JSON.stringify(this.vacationTime))
        //                         arr.unshift({s: domains[i].startTime, e: domains[i].endTime})
        //                         for(var j in arr) {
        //                             subtractedData += +this.timeOverlap(j, arr)
        //                         }
        //                         console.log(selectionTime, subtractedData)
        //                         hours += +selectionTime - +subtractedData
        //                         console.log(hours)
        //                     }
        //                 } else {
        //                     hours += domains[i].workingTime ? parseFloat(domains[i].workingTime) : 0
        //                 }
        //             }
        //         }
        //     }
        //     return hours.toFixed(2)
        // },
    },

    methods: {

        //选中角色
        choseRole(value, index) {
            if (value) {
                this.form.domains[this.clickIndex].extraField1 = value.value;
                this.form.domains[this.clickIndex].extraField1Name = value.label;
            }
            this.form.domains[this.clickIndex].showPickerRole = false;
            this.updateResponse(this.form.domains[this.clickIndex]);
            this.$forceUpdate();
        },
        choseWorkContent(value, index) {
            if (value) {
                this.form.domains[this.clickIndex].extraField3 = value.id;
                this.form.domains[this.clickIndex].extraField3Name = value.workContext;
            }
            this.form.domains[this.clickIndex].showPickerWorkContent = false;
            this.$forceUpdate();
        },
        onResponseChosen(value, index) {
            if (value) {
                this.form.domains[this.clickIndex].extraField2 = value.id;
                this.form.domains[this.clickIndex].extraField2Name = value.jobRespon;
                this.form.domains[this.clickIndex].extraField3 = null;
                this.form.domains[this.clickIndex].extraField3Name = null;
            }
            this.form.domains[this.clickIndex].showPickerRespon = false;
            this.updateJobContent(this.form.domains[this.clickIndex]);
        },
        clickPickWorkContent(i, item) {
            if (!item.canEdit) return;
            this.clickIndex = i;
            item.showPickerWorkContent = true;
            this.$forceUpdate();
        },
        clickPickRespon(i, item) {
            if (!item.canEdit) return;
            this.clickIndex = i;
            item.showPickerRespon = true;
            this.$forceUpdate();
        },

        //更新工作内容列表  
        updateJobContent(domainItem) {
            var that = this;
            this.$axios.post('/project/getFunWorkContextList', { id: domainItem.extraField2 })
                .then(res => {
                    if (res.code == 'ok') {
                        that.$set(domainItem, 'extraField3', null);
                        that.$set(domainItem, 'workContentList', res.data);
                    }
                }).catch(err => { })
        },
        updateResponse(domainItem) {
            console.log(111);
            var that = this;
            var selectPid = domainItem.projectId;
            domainItem.extraField2 = null;
            domainItem.extraField3 = null;
            domainItem.extraField2Name = null;
            domainItem.extraField3Name = null;
            if (selectPid && domainItem.extraField1) {
                //是否非项目
                var isPublic = this.projectss.filter(p => p.id == selectPid)[0].isPublic;
                console.log('==========', 'isPublic==' + isPublic);
                var filteredRespList = this.jobResponseList.filter(j => j.timeType == (isPublic ? '非项目工时' : '项目工时') && j.role == (this.roleList.filter(r => r.value == domainItem.extraField1)[0].label));
                domainItem.filteredRespList = filteredRespList;
            }

        },
        getJobResponList() {
            this.$axios.post('/project/getFunctionalDivisionList', {})
                .then(res => {
                    if (res.code == 'ok') {
                        this.jobResponseList = res.data;
                    }
                }).catch(err => { })
        },

        getRecentlyProject() {
            this.$axios.post('/project/nearProject', {})
                .then(res => {
                    if (res.code == 'ok') {
                        this.integrationProjectList = res.data;
                        //去掉全部项目中没有的
                        this.setTimeout(() => {
                            this.integrationProjectList = this.integrationProjectList.filter(near => this.getProjectAuditorList.find(pItem => pItem.id == near.id) != null);
                        }, 1000);

                    }
                }).catch(err => { this.$toast.clear(); this.cardRefLoading = false; })
        },
        // 判断两个时间段是否重叠
        timeOverlap(idx, dateAr) {
            let zhi = 0
            for (let k in dateAr) {
                if (idx !== k) {
                    if (((dateAr[k].s <= dateAr[idx].s && dateAr[k].e >= dateAr[idx].s) || (dateAr[k].s <= dateAr[idx].s && dateAr[k].e <= dateAr[idx].e))) {
                        // 选择的时间包含设置的休息时间段 （选择的开始时间和结束时间大于设置的休息时间段）
                        if (dateAr[idx].s > dateAr[k].s && dateAr[idx].e < dateAr[k].e) {
                            // zhi += 2
                            zhi += +this.getHour(dateAr[idx].s, dateAr[idx].e)
                        }
                        // 选择的时间包含在设置的休息时间 （选择的开始时间和结束时间都处于在设置的休息时间段内）
                        if (dateAr[idx].s > dateAr[k].s && dateAr[idx].e > dateAr[k].e) {
                            zhi += +this.getHour(dateAr[idx].s, dateAr[k].e)
                        } else if (dateAr[idx].s < dateAr[k].s && dateAr[idx].e > dateAr[k].e) {
                            zhi += +this.getHour(dateAr[k].e, dateAr[idx].s)
                        } else {
                            // 选择的结束时间处于设置的休息时间段内 （选择的结束时间处于的休息时间段）
                            if (dateAr[k].e < dateAr[idx].e && dateAr[k].e > dateAr[idx].s) {
                                zhi += +this.getHour(dateAr[k].e, dateAr[idx].e)
                            }
                            // 选择的开始时间处于设置的休息时间段内 （选择的开始时间处于的休息时间段）
                            if (dateAr[idx].s > dateAr[k].s && dateAr[idx].s < dateAr[k].e) {
                                zhi += +this.getHour(dateAr[k].e, dateAr[idx].s)
                            }
                        }
                    }
                }
            }
            return zhi
        },
        getHour(s1, s2) {
            var reDate = /\d{4}-\d{1,2}-\d{1,2} /;
            s1 = new Date((reDate.test(s1) ? s1 : '2018-1-1 ' + s1).replace(/-/g, '/'));
            s2 = new Date((reDate.test(s2) ? s2 : '2018-1-1 ' + s2).replace(/-/g, '/'));
            var ms = s2.getTime() - s1.getTime();
            if (ms < 0) return 0;
            let ma = ms / 1000 / 60 / 60
            return ma;  //小时
        },

        cardtimeRefresh(workdate) {  // 考勤记录刷新
            if (!workdate) return;
            this.cardRefLoading = true;
            this.$axios.post('/user-corpwx-time/getPunchRecordBySelf', {
                date: workdate
            })
                .then(res => {
                    if (res.code == 'ok') {
                        this.cardRefLoading = false;
                        this.report.time = res.data.time;
                    } else {
                        this.cardRefLoading = false;
                        this.$toast.fail('获取失败:' + res.msg);
                    }
                }).catch(err => { this.$toast.clear(); this.cardRefLoading = false; })
        },
        auditorClick(domainIndex, auditorIndex) {
            if (this.canEdit) {
                this.auditor.index = domainIndex
                this.auditor.auditorIndex = auditorIndex
                this.auditor.searchList.forEach(u => {
                    if (this.form.domains[domainIndex][auditorIndex] && u.id == this.form.domains[domainIndex][auditorIndex].id) {
                        this.auditor.item = u
                    }
                })
                this.auditorShow = true
            }
        },
        onSearch(val) {
            console.log(val);
            this.auditor.searchList = [];
            this.auditor.list.forEach(u => {
                if (u.name.startsWith(val)) {
                    this.auditor.searchList.push(u);
                }
            })
        },
        searchAuditor() {
            if(!this.substitute) {
                this.form.domains[this.auditor.index][this.auditor.auditorIndex] = this.auditor.item
            }
            if(this.substitute) {
                const { id, name } = this.auditor.item
                this.fillingAgent = { id, name }
            }
            this.auditorShow = false
            console.log('searchAuditor');
        },
        getAllUsersList() {
            this.$axios.post('/user/getSimpleActiveUserList', {})
                .then(res => {
                    if (res.code == 'ok') {
                        this.auditor.list = res.data
                        this.auditor.searchList = res.data
                    } else {
                        this.$toast.fail('获取失败:' + res.msg);
                    }
                }).catch(err => { this.$toast.clear(); })
        },
        requiredRule(val) {
            console.log('requiredRule', val);
            return false
        },


        // 获取预算来源
        getReportBasecostList() {
            this.$axios.post('/project-basecost-setting/getReportBasecostList', {
                companyId: this.user.companyId
            }).then(res => {
                if (res.code == 'ok') {
                    this.reportBasecostList = res.data
                } else {
                    this.$toast.fail('获取失败:' + res.msg);
                }
            }).catch(err => { this.$toast.clear(); })
        },
        //获取项目审核人
        getProjectAuditorList(domainItem) {
            this.$axios.post("/project-auditor/getList", { projectId: domainItem.projectId })
                .then(res => {
                    if (res.code == "ok") {
                        domainItem.auditUserList = res.data;
                        if (res.data.length == 1) {
                            domainItem.projectAuditorId = domainItem.auditUserList[0].auditorId;
                            domainItem.projectAuditorName = domainItem.auditUserList[0].auditorName;
                        }
                        this.$forceUpdate();
                    } else {
                        this.$toast.fail('获取失败:' + res.msg);
                    }
                }).catch(err => { this.$toast.clear(); });
        },

        updateTxt() {
            this.$forceUpdate();
        },
        // 获取项目
        getPeoject() {
            this.$axios.post("/project/getProjectList", { forReport: 1 })
                .then(res => {
                    if (res.code == "ok") {
                        for (var i in res.data) {
                            if (res.data[i].projectCode == 'null' || res.data[i].projectCode == null) {
                                res.data[i].projectCode = ' '
                            }
                        }
                        this.projectss = res.data;
                        this.project = res.data;
                        this.projectss = this.projectss.filter(p => p.status == 1 || p.status == 4);
                        this.proads = res.data
                        this.getRecentlyProject()
                    } else {
                        this.$toast.fail('获取失败:' + res.msg);
                    }
                }).catch(err => { this.$toast.clear(); });
        },
        sea() {
            // console.log(this.userName.length)
            if (this.userName.length > 0) {
                // let text = this.userName
                // let reg = new RegExp(text)
                // let data = this.proads.filter(item => reg.test(item.projectName)) //返回
                // let datas = this.proads.filter(item => reg.test(item.projectCode))
                // let dataTree = data.concat(datas)
                // let arrList = Array.from(new Set(dataTree))
                // this.projectss = arrList
                // console.log(this.userName.toUpperCase())
                let data = this.proads.filter(item => { return item.projectName.indexOf(this.userName.toUpperCase()) != '-1' });
                let dataList = this.proads.filter(item => { return item.projectCode.indexOf(this.userName.toUpperCase()) != '-1' });
                let dataTree = data.concat(dataList)
                let arrList = Array.from(new Set(dataTree))
                this.projectss = arrList
            } else {
                // console.log(456)
                this.projectss = this.proads
            }
        },

        selectDegreeId(domain, index, domainItem, list) {
            this.$axios.post("/report/defaultDegree", {
                projectId: domain.id
            })
                .then(res => {
                    if (res.code == "ok") {
                        var arr = domainItem.wuduList
                        for (var i in arr) {
                            if (arr[i].id == res.data.id) {
                                domainItem.weiduName = arr[i].name
                                domainItem.degreeId = res.data.id
                            }
                        }
                        this.$forceUpdate();
                    } else {
                        this.$toast.fail('获取失败:' + res.msg);
                    }
                }).catch(err => { this.$toast.clear(); });
        },

        fZr(item, index) {

            var domainItem = this.form.domains[this.clickIndex];
            domainItem.projectId = item.id;
            domainItem.projectName = item.projectName;

            //清空子项目
            domainItem.subProjectId = null;
            domainItem.subProjectName = null;
            //清空任务
            domainItem.taskId = null;
            domainItem.taskName = null;
            //清空分组和阶段
            domainItem.stage = null;
            domainItem.groupId = null;
            domainItem.weiduName = null;

            this.showPickerUserddp = false;
            //获取子项目
            if (this.user.timeType.mainProjectState != 1) {
                this.getSubprojectList(this.form.domains[this.clickIndex]);
            }
            //加载项目相关的工程进度
            if (this.user.company.packageEngineering == 1) {
                this.getProjectProfessions(this.form.domains[this.clickIndex], index);
            }
            //获取任务分组
            this.getTaskGroups(this.form.domains[this.clickIndex], this.clickIndex);

            // 获取1相关维度
            this.getTaskList(domainItem)
            this.dimension(domainItem.projectId)
            //获取相关日报提交选择人
            domainItem.projectAuditorId = null;
            domainItem.projectAuditorName = null;
            domainItem.auditUserList = null;
            //获取项目审核人
            // console.log(domainItem.projectId,this.project);
            var curProject = this.project.filter(p => p.id == domainItem.projectId)[0];
            if (curProject && (this.user.timeType.reportAuditType == 0 || this.user.timeType.reportAuditType == 4)) {
                this.getProjectAuditorList(domainItem, index);
            }

            console.log(item, '数据')
            if (this.user.timeType.customDegreeActive == 1 && item) {
                var that = this
                setTimeout(() => {
                    that.selectDegreeId(item, index, that.form.domains[that.clickIndex])
                }, 500)
            }
            //加载昱众的工作职责
            if (this.user.companyId == this.yuzhongCompId) {
                this.updateResponse(domainItem);
            }
        },
        //获取项目下的任务分组
        getTaskGroups(domainItem, index) {
            domainItem.groupId = null;
            domainItem.groupName = null;
            this.$axios.post("/task-group/listMyJoinGroup", { projectId: domainItem.projectId })
                .then(res => {
                    if (res.code == "ok") {
                        domainItem.taskGroups = res.data;
                    }
                    this.$forceUpdate();
                }).catch(err => { this.$toast.clear(); });
        },
        getSubprojectList(domainItem) {
            domainItem.subProjectList = [];
            this.$axios.post("/sub-project/list", { projectId: domainItem.projectId,isReport:true })
                .then(res => {
                    if (res.code == "ok") {
                        domainItem.subProjectList = res.data;
                    }
                    this.$forceUpdate();
                }).catch(err => { this.$toast.clear(); });
        },
        showEndDialog(timeItem) {
            this.curWorktime = timeItem;
            this.showWorkEndTime = true;
        },
        showStartDialog(timeItem) {
            this.curWorktime = timeItem;
            this.showWorkStartTime = true;
        },
        removeTimeItem(item, index) {
            item.worktimeList.splice(index, 1);
        },
        isIOS() {
            var u = navigator.userAgent;
            var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            return isiOS;
        },

        showLargeImg(item, index) {
            this.imgShow = true;
            this.tmpPics = item;
        },
        //拍照上传
        takePhoto(index) {
            var that = this;
            wx.chooseImage({
                count: 9, // 默认9
                sizeType: ['compressed'], // 可以指定是原图还是压缩图，默认二者都有
                sourceType: that.user.timeType.choseFromAlbum == 1 ? ['camera', 'album'] : ['camera'], // 可以指定来源是相册还是相机，默认二者都有
                defaultCameraMode: "batch", //表示进入拍照界面的默认模式，目前有normal与batch两种选择，normal表示普通单拍模式，batch表示连拍模式，不传该参数则为normal模式。从3.0.26版本开始支持front和batch_front两种值，其中front表示默认为前置摄像头单拍模式，batch_front表示默认为前置摄像头连拍模式。（注：用户进入拍照界面仍然可自由切换两种模式）
                isSaveToAlbum: 0, //整型值，0表示拍照时不保存到系统相册，1表示自动保存，默认值是1
                success: function (res) {
                    var localIds = res.localIds; // 返回选定照片的本地ID列表，
                    // andriod中localId可以作为img标签的src属性显示图片；
                    // iOS应当使用 getLocalImgData 获取图片base64数据，从而用于img标签的显示（在img标签内使用 wx.chooseImage 的 localid 显示可能会不成功）
                    if (that.form.domains[index].pics == null) {
                        that.form.domains[index].pics = [];
                        that.form.domains[index].iospics = [];
                    }
                    if (that.isIOSystem) {
                        for (var i = 0; i < localIds.length; i++) {
                            wx.getLocalImgData({
                                localId: localIds[i], // 图片的localID
                                success: function (res) {
                                    var localData = res.localData; // localData是图片的base64数据，可以用img标签显示
                                    that.form.domains[index].iospics.push(localData);
                                    that.$forceUpdate();
                                }
                            });
                        }

                        if (that.user.companyId == 7) {
                            that.form.domains[index].pics = that.form.domains[index].pics.concat(localIds);
                        } else {
                            that.form.domains[index].pics = localIds;
                        }
                    } else {
                        if (that.user.companyId == 7) {
                            that.form.domains[index].pics = that.form.domains[index].pics.concat(localIds);
                        } else {
                            that.form.domains[index].pics = localIds;
                        }
                        that.$forceUpdate();
                    }
                    // var serverIdList = [];
                    if (that.form.domains[index].serverPics == null) {
                        that.form.domains[index].serverPics = [];
                    }
                    //立即就上传到企业微信服务器
                    for (var i = 0; i < localIds.length; i++) {
                        wx.uploadImage({
                            localId: localIds[i], // 需要上传的图片的本地ID，由chooseImage接口获得
                            isShowProgressTips: 1, // 默认为1，显示进度提示
                            success: function (res) {
                                var serverId = res.serverId; // 返回图片的服务器端ID
                                // serverIdList.push(serverId);
                                that.form.domains[index].serverPics.push(serverId);
                            }
                        });
                    }
                }
            });
        },
        copyProject(index) {
            var leftProgress = 10;
            if (this.reportTimeType.type == 3) {
                //计算已经待分配工时比例
                let array = this.form.domains;
                let totalProgress = 0;
                for (var i = 0; i < array.length; i++) {
                    totalProgress += array[i].progress;
                }
                if (totalProgress < 100) {
                    leftProgress = 100 - totalProgress;
                }
            }
            var newIndex = index + 1;
            var pName = "";
            if (this.form.domains[index].projectId != '') {
                pName = this.project.filter(p => p.id == this.form.domains[index].projectId)[0].projectName;
            }
            var itemDomain = {
                id: null,
                projectId: this.form.domains[index].projectId,
                projectName: pName,
                workingTime: this.reportTimeType.type == 3 ? (leftProgress * this.reportTimeType.allday / 100).toFixed(1) : "",
                progress: leftProgress,
                content: "",
                state: 2,
                isOvertime: false,
            };
            this.form.domains.splice(newIndex, 0, itemDomain);
        },
        //删除日报
        deleteReport() {
            this.$dialog.confirm({
                title: '删除日报',
                message: '确定要删除当天日报吗?'
            }).then(() => {
                const toast = this.$toast.loading({
                    forbidClick: true,
                    duration: 0
                });
                this.$axios.post("/report/delete", { userId: this.user.id, date: this.form.createDate })
                    .then(res => {
                        if (res.code == "ok") {
                            this.$toast.clear();
                            this.$toast.success('删除成功');
                            window.location.reload();
                        } else {
                            this.$toast.clear();
                            this.$toast.fail('删除失败');
                        }
                    }).catch(err => { this.$toast.clear(); });
            }).catch(() => { });
        },
        changeAllTime() {
            //总时长发生改变，自动按比例计算
            this.form.domains.forEach(d => {
                d.workingTime = (d.progress * this.reportTimeType.allday / 100).toFixed(1);
            });
        },
        cancel() {
            const toast = this.$toast.loading({
                forbidClick: true,
                duration: 0
            });
            var ids = '';
            var data = this.form.domains;
            data.forEach(element => {
                if (element.id != null && element.id != '') {
                    if (element.id) {
                        ids += (element.id + ',');
                    }
                }
            });
            this.$axios.post("/report/cancel", { userId: this.user.id, reportIds: ids })
                .then(res => {
                    if (res.code == "ok") {
                        this.$toast.clear();
                        this.$toast.success('撤销成功');
                        this.getReport();
                    } else {
                        this.$toast.clear();
                        this.$toast.fail('获取失败');
                    }
                }).catch(err => { this.$toast.clear(); });
        },

        confirmWorkTime(field) {
            if (field == 0) {
                this.curWorktime.startTime = this.startTime;
                this.showWorkStartTime = false;
            } else {
                this.curWorktime.endTime = this.endTime;
                this.showWorkEndTime = false;
            }
            this.setTotalReportHours()
        },

        confirmTime(item, field, index) {
            if (field == 0) {
                console.log(this.dailyIndex)
                // item.startTime = this.startTime;
                this.form.domains[this.dailyIndex].startTime = this.startTime;
                this.showStartTime = false;
            } else {
                this.form.domains[this.dailyIndex].endTime = this.endTime;
                // item.endTime = this.endTime;
                this.showEndTime = false;
            }
            console.log(this.form.domains)
            this.setTotalReportHours()
        },

        setTotalReportHours() {
            let domains = this.form.domains
            let hours = 0
            let arrNum = [] //  用于时间段计算加班时间
            let timeArr = []
            for (let i in domains) {
                if (this.reportTimeType.multiWorktime == 1) {
                    for (let m in domains[i].worktimeList) {
                        if (domains[i].worktimeList[m].startTime && domains[i].worktimeList[m].endTime) {
                            // hours += this.getHour(domains[i].worktimeList[m].startTime, domains[i].worktimeList[m].endTime)
                            timeArr.push({ startTime: domains[i].worktimeList[m].startTime, endTime: domains[i].worktimeList[m].endTime })
                        }
                    }
                } else {
                    if (this.user.timeType.type == 2) {
                        if (domains[i].startTime && domains[i].endTime) {
                            // let selectionTime = this.getHour(domains[i].startTime, domains[i].endTime)
                            // let subtractedData = 0
                            // let arr = JSON.parse(JSON.stringify(this.vacationTime))
                            // arr.unshift({s: domains[i].startTime, e: domains[i].endTime})
                            // for(var j in arr) {
                            //     subtractedData += +this.timeOverlap(j, arr)
                            // }
                            // console.log(selectionTime, subtractedData)
                            // hours += +selectionTime - +subtractedData
                            // console.log(hours)
                            // arrNum.push(+selectionTime - +subtractedData)
                            timeArr.push({ startTime: domains[i].startTime, endTime: domains[i].endTime })
                        }
                    } else {
                        hours += domains[i].workingTime ? parseFloat(domains[i].workingTime) : 0
                    }
                }
            }

            // this.totalReportHours = hours.toFixed(2)
            console.log('timeArr', timeArr)
            if (this.reportTimeType.multiWorktime == 1 || this.user.timeType.type == 2) {
                const { timeCanOverlap } = this.reportTimeType // timeCanOverlap 为零时校验不允许重叠
                if(timeCanOverlap == 0) {
                    this.getTimeHours(timeArr)
                } else {
                    const newTimeArr = this.mergeTimeIntervals(timeArr)
                    this.getTimeHours(newTimeArr)
                }
            } else {
                this.totalReportHours = hours.toFixed(2)
            }
        },
        // 处理重叠时间段
        mergeTimeIntervals(timeIntervals) {
            // 按照开始时间排序
            timeIntervals.sort((a, b) => a.startTime.localeCompare(b.startTime));
        
            const mergedIntervals = [];
            for (const interval of timeIntervals) {
                if (
                    mergedIntervals.length === 0 ||
                    interval.startTime > mergedIntervals[mergedIntervals.length - 1].endTime
                ) {
                    // 当前时间段与存储数组中的时间段没有重叠，直接加入存储数组
                    mergedIntervals.push(interval);
                } else if (interval.endTime > mergedIntervals[mergedIntervals.length - 1].endTime) {
                    // 当前时间段与存储数组中的时间段有重叠，但是未完全包含，需要合并时间段
                    mergedIntervals[mergedIntervals.length - 1].endTime = interval.endTime;
                }
            }
            return mergedIntervals;
        },
        getTimeHours(arr) {
            this.$axios.post("/report/getHoursByTimeRange", { timeJsonStr: JSON.stringify(arr) })
                .then(res => {
                    if (res.code == "ok") {
                        this.totalReportHours = res.data
                    } else {
                        this.$toast.clear();
                        this.$toast.fail(res.msg);
                    }
                }).catch(err => { this.$toast.clear(); });
        },

        filter(type, options) {
            if (type === 'minute') {
                return options.filter(option => option % 30 === 0);
            }

            return options;
        },
        choseTimePick(value, index) {
            if (!value) {
                return
            }
            //选中时间
            if (this.reportTimeType.type == 0) {
                this.form.domains[this.clickTimeIndex].timeType = value.value;
                this.form.domains[this.clickTimeIndex].workingTime = value.hours;
                this.form.domains[this.clickTimeIndex].label = value.label;
                this.showPickerTime = false;
            } else if (this.reportTimeType.type == 1) {
                // console.log('this.reportTimeType.type=='+value,this.form.domains);
                this.form.domains[this.clickTimeIndex].workingTime = value;
                this.form.domains[this.clickTimeIndex].label = value.toFixed(1) + '小时';
                this.showPickerHours = false;

                let allhour = 0
                for (let i in this.form.domains) {
                    if (this.isWeekend) {
                        this.form.domains[i].isOvertime = true
                        this.form.domains[i].overtimeHours = this.form.domains[i].workingTime
                    } else {
                        this.form.domains[i].isOvertime = false
                        delete this.form.domains[i].overtimeHours
                    }
                    allhour += this.form.domains[i].workingTime * 1
                }
                if (allhour > this.user.timeType.allday && !this.isWeekend) {
                    this.form.domains[this.clickTimeIndex].isOvertime = true
                    this.form.domains[this.clickTimeIndex].overtimeHours = allhour - this.user.timeType.allday
                }
            }

            this.setTotalReportHours()
        },
        clickTimePicker(i, item) {
            if (!item.canEdit) {
                return;
            }
            this.clickTimeIndex = i;

            if (this.reportTimeType.type == 0) {
                this.showPickerTime = true;
            } else if (this.reportTimeType.type == 1) {
                this.showPickerHours = true;
            }
        },
        getTimeType() {
            this.$axios.post('/time-type/getCompanyTimeSetting', { companyId: this.user.companyId })
                .then(res => {
                    if (res.code == "ok") {
                        var t = res.data;
                        this.reportTimeType = t;
                        //转化时间格式
                        if (t.allday != null) {
                            this.timeType.push({ value: 0, label: '全天 - ' + t.allday + '小时', hours: t.allday });
                        }
                        if (t.am != null) {
                            this.timeType.push({ value: 1, label: '上午 - ' + t.am + '小时', hours: t.am });
                        }
                        if (t.pm != null) {
                            this.timeType.push({ value: 2, label: '下午 - ' + t.pm + '小时', hours: t.pm });
                        }

                        let arr = []
                        for (var i in res.data.excludeTimeList) {
                            let obj = {
                                s: res.data.excludeTimeList[i].startTime,
                                e: res.data.excludeTimeList[i].endTime,
                            }
                            arr.push(obj)
                        }
                        this.vacationTime = arr
                    } else {
                        this.$toast.clear();
                        this.$toast.fail(res.msg);
                    }
                }).catch(err => { this.$toast.clear(); });
        },

        // 返回
        back() {
            history.back();
        },

        // 时间转换
        format(date, pattern) {
            pattern = pattern || "yyyy-MM-dd";
            var _this = this;
            return pattern.replace(/([yMdhsm])(\1*)/g, function ($0) {
                switch ($0.charAt(0)) {
                    case 'y': return _this.padding(date.getFullYear(), $0.length);
                    case 'M': return _this.padding(date.getMonth() + 1, $0.length);
                    case 'd': return _this.padding(date.getDate(), $0.length);
                    case 'w': return date.getDay() + 1;
                    case 'h': return _this.padding(date.getHours(), $0.length);
                    case 'm': return _this.padding(date.getMinutes(), $0.length);
                    case 's': return _this.padding(date.getSeconds(), $0.length);
                }
            });
        },

        padding(s, len) {
            var len = len - (s + '').length;
            for (var i = 0; i < len; i++) { s = '0' + s; }
            return s;
        },

        // 获取项目
        getProject() {
            const toast = this.$toast.loading({
                forbidClick: true,
                duration: 0
            });
            this.$axios.post("/project/getProjectList", {})
                .then(res => {
                    if (res.code == "ok") {
                        this.$toast.clear();


                        // if (this.project.length > 0) {
                        //     console.log('this.project[0].id=='+this.project[0].id);
                        //     this.getTaskList(this.project[0].id);
                        // }
                    } else {
                        this.$toast.clear();
                        this.$toast.fail('获取失败:' + res.msg);
                    }
                }).catch(err => { this.$toast.clear(); });
        },
        getAIReport(createDate) {
            const toast = this.$toast.loading({
                forbidClick: true,
                duration: 0
            });
            this.$axios.post('/report/getAIReport', {})
            .then(res => {
                if (res.code == "ok") {
                    var quanbu = 0 
                    this.jsDay = 1
                    var zhi = ''
                    var slp = res.data
                    for(var i in slp) {
                        var sp = +slp[i].workingTime + 0
                        zhi = this.jsDay * sp
                        quanbu = +quanbu + zhi
                    }
                    
                    this.jsTime = quanbu
                    var aiReportData = res.data;
                    this.canCancelInDialog = false
                    if(aiReportData.length != 0) {
                        var arr = [];
                        var list = aiReportData;
                        for(var i in list) {
                            var subProjectName = null;
                            if (list[i].subProjectId) {
                                subProjectName = list[i].subProjectList.filter(s => s.id == list[i].subProjectId)[0].name;
                            }
                            var projectName = list[i].projectName;
                            arr.push({
                                projectId: list[i].projectId,
                                projectName: projectName,
                                workingTime: String(list[i].workingTime),
                                content: list[i].content,
                                state: list[i].state,
                                timeType: list[i].timeType,
                                // label: timeType[list[i].timeType].label,
                                startTime: list[i].startTime,
                                endTime: list[i].endTime,
                                isOvertime: 0,
                                progress: list[i].progress,
                                taskList: list[i].taskList,
                                taskId: list[i].taskId ? list[i].taskId : null,
                                taskName: list[i].taskName,
                                professionProgress: list[i].professionProgressList,
                                pics: null,
                                multiWorktime: list[i].multiWorktime,
                                worktimeList: list[i].worktimeList,
                                wuduList: list[i].degreeList,
                                degreeId: list[i].degreeId==-1?null:list[i].degreeId,
                                subProjectId: list[i].subProjectId,
                                subProjectName: subProjectName,
                                subProjectList: list[i].subProjectList,
                                groupId: list[i].groupId == 0 ? null : list[i].groupId,
                                groupName: list[i].groupName,
                                taskGroups: list[i].taskGroups,
                                stages: list[i].stages,
                                stage: list[i].stage == '-' ? null : list[i].stage,
                                customData: list[i].customData,
                                projectAuditorId: list[i].projectAuditorId,
                                projectAuditorName: list[i].projectAuditorName,
                                auditUserList: list[i].auditUserList,
                                overtimeHours: list[i].overtimeHours,
                                customText: list[i].customText,
                                basecostId: list[i].basecostId,
                                //basecostName: basecostName,
                                canEdit: true,
                                auditorFirst: list[i].auditorFirst ? list[i].auditorFirst : { name: '', id: '' },
                                auditorSec: list[i].auditorSec ? list[i].auditorSec : { name: '', id: '' },
                                auditorThird: list[i].auditorThird ? list[i].auditorThird : { name: '', id: '' },
                                ccUserid: list[i].ccUserid ? list[i].ccUserid : { name: '', id: '' },
                                extraField1: list[i].extraField1,
                                extraField1Name: list[i].extraField1Name,
                                extraField2: list[i].extraField2,
                                extraField2Name: list[i].extraField2Name,
                                extraField3: list[i].extraField3,
                                extraField3Name: list[i].extraField3Name,
                                workContentList: list[i].workContentList,
                                sapServiceId: list[i].sapServiceId,
                                sapServiceName: list[i].sapServiceName,
                                serviceList: list[i].serviceList,
                                userReportDeptName: list[i].userReportDeptName,
                                reportTargetDeptId: list[i].deptId
                            })
                        }
                        this.form.domains = arr;
                    } else {
                        
                    }
                    this.canEdit = true;
                    this.originCanEdit = true;
                    this.seleChn()
                } else {
                    this.$message({
                        message: res.msg,
                        type: "error"
                    });
                }
            }).catch(err => { this.$toast.clear(); });
        },
        // 获取日报
        getReport() {
            this.isWeekend = false
            let weekday = new Date(this.form.createDate).getDay()
            if (this.user.companyId == 817) {
                if (weekday == 0) {
                    this.isWeekend = true
                }
            } else {
                if (weekday == 0 || weekday == 6) {
                    this.isWeekend = true
                }
            }
            const toast = this.$toast.loading({
                forbidClick: true,
                duration: 0
            });
            this.$axios.post("/report/getReport", { date: this.form.createDate })
                .then(res => {
                    if (res.code == "ok") {
                        this.$toast.clear();
                        this.report = res.data;
                        var t = res.data.timeType;
                        var timeType = [];
                        //转化时间格式
                        if (t.allday != null) {
                            timeType.push({ value: 0, label: '全天 - ' + t.allday + '小时', hours: t.allday });
                        }
                        if (t.am != null) {
                            timeType.push({ value: 1, label: '上午 - ' + t.am + '小时', hours: t.am });
                        }
                        if (t.pm != null) {
                            timeType.push({ value: 2, label: '下午 - ' + t.pm + '小时', hours: t.pm });
                        }
                        var list = res.data.report;
                        this.canCancelInDialog = false
                        this.hasWrittenReport = list.length != 0;
                        if (list.length != 0 && !this.substitute) {
                            this.canEdit = false;
                            this.canCancel = false;
                            this.canDeleteReport = true
                            let array = [];
                            for (var i in list) {
                                var projectName = list[i].projectName;
                                var flg = (list[i].isOvertime == 1);
                                for (var j in this.project) {
                                    if (this.project[j].id == list[i].projectId) {
                                        projectName = this.project[j].projectName;
                                    }
                                }
                                console.log(projectName);
                                let tname = '';
                                if (list[i].taskId != null && list[i].taskList.length > 0) {
                                    let filterList = list[i].taskList.filter(t => t.taskId == list[i].taskId);
                                    if (filterList.length > 0) {
                                        tname = filterList[0].taskName;
                                    }
                                }
                                let mmm = !t.customDegreeMultiple ? list[i].degreeId : (list[i].multiDegrIdList || [])
                                let sss = list[i].degreeList || []
                                let radioPickDegree = ''
                                let wuduName = ''
                                if(!t.customDegreeMultiple) {
                                    for (var s in sss) {
                                        if (sss[s].id == mmm) {
                                            wuduName = sss[s].name
                                        }
                                    }
                                    radioPickDegree = sss.filter(s => s.id == mmm)[0]
                                } else {
                                    let arr = sss.filter(s => mmm.includes(s.id)).map(s => s.name);
                                    radioPickDegree = sss.filter(s => mmm.includes(s.id))
                                    wuduName = arr.join(',')
                                }
                                let serverPicArray = [];
                                // console.log('picArray=='+list[i].picStr);
                                if (list[i].picStr != null && list[i].picStr != '@') {
                                    serverPicArray = JSON.parse(list[i].picStr.replace(/@/g, ","));
                                }

                                var subProjectName = null;
                                if (list[i].subProjectId) {
                                    subProjectName = list[i].subProjectList.filter(s => s.id == list[i].subProjectId)[0].name;
                                }
                                let basecostName = ''
                                if (res.data.timeBasecostList && res.data.timeBasecostList.length > 0) {
                                    for (let m in res.data.timeBasecostList) {
                                        if (res.data.timeBasecostList[m].id == list[i].basecostId) {
                                            basecostName = res.data.timeBasecostList[m].name
                                        }
                                    }
                                }
                                var targetP = this.project.filter(p => p.id == list[i].projectId);
                                var filteredRespList = [];
                                if (targetP.length > 0 && list[i].extraField1) {
                                    list[i].extraField1Name = this.roleList.find(r => r.value == list[i].extraField1).label;
                                    var isPublic = targetP[0].isPublic;
                                    filteredRespList = this.jobResponseList.filter(j => j.timeType == (isPublic ? '非项目工时' : '项目工时') && j.role == this.roleList.filter(r => r.value == list[i].extraField1)[0].label);
                                    if (list[i].extraField2) {
                                        list[i].extraField2Name = filteredRespList.find(f => f.id == list[i].extraField2).jobRespon;
                                    }
                                    if (list[i].extraField3) {
                                        list[i].extraField3Name = list[i].workContentList.find(w => w.id == list[i].extraField3).workContext;
                                    }
                                }
                                let copyData = {
                                    id: list[i].id,
                                    projectId: list[i].projectId,
                                    projectName: projectName,
                                    workingTime: String(list[i].workingTime),
                                    content: list[i].content,
                                    state: list[i].state,
                                    timeType: list[i].timeType,
                                    label: timeType[list[i].timeType].label,
                                    startTime: list[i].startTime,
                                    endTime: list[i].endTime,
                                    isOvertime: flg,
                                    progress: list[i].progress,
                                    taskList: list[i].taskList,
                                    taskId: list[i].taskId ? list[i].taskId : null,
                                    taskName: tname,
                                    professionProgress: list[i].professionProgressList,
                                    pics: list[i].pics,
                                    iospics: list[i].pics,
                                    serverPics: serverPicArray,
                                    multiWorktime: t.multiWorktime,
                                    worktimeList: list[i].worktimeList,
                                    wuduList: list[i].degreeList,
                                    weiduName: wuduName,
                                    degreeId: !t.customDegreeMultiple ? list[i].degreeId : list[i].multiDegrIdList,
                                    radioPickDegree: radioPickDegree,
                                    subProjectId: list[i].subProjectId,
                                    subProjectName: subProjectName,
                                    subProjectList: list[i].subProjectList,
                                    groupId: list[i].groupId == 0 ? null : list[i].groupId,
                                    groupName: list[i].groupName,
                                    taskGroups: list[i].taskGroups,
                                    stages: list[i].stages,
                                    stage: list[i].stage == '-' ? null : list[i].stage,
                                    customData: list[i].customData,
                                    auditUserList: list[i].auditUserList,
                                    overtimeHours: list[i].overtimeHours,
                                    customText: list[i].customText,
                                    basecostId: list[i].basecostId,
                                    basecostName: basecostName,
                                    canEdit: list[i].state >= 2 ? true : false,
                                    auditorFirst: list[i].auditorFirst ? list[i].auditorFirst : { name: '', id: '' },
                                    auditorSec: list[i].auditorSec ? list[i].auditorSec : { name: '', id: '' },
                                    auditorThird: list[i].auditorThird ? list[i].auditorThird : { name: '', id: '' },
                                    ccUserid: list[i].ccUserid ? list[i].ccUserid : { name: '', id: '' },
                                    extraField1: list[i].extraField1,
                                    extraField1Name: list[i].extraField1Name,
                                    extraField2: list[i].extraField2,
                                    extraField2Name: list[i].extraField2Name,
                                    extraField3: list[i].extraField3,
                                    extraField3Name: list[i].extraField3Name,
                                    workContentList: list[i].workContentList,
                                    filteredRespList: filteredRespList,
                                    sapServiceId: list[i].sapServiceId,
                                    sapServiceName: list[i].sapServiceName,
                                    serviceList: list[i].serviceList,
                                    userReportDeptName: list[i].userReportDeptName,
                                    reportTargetDeptId: list[i].deptId
                                }
                                if (this.user.timeType.reportAuditType != 1 && this.user.timeType.reportAuditType != 2 && this.user.timeType.reportAuditType != 9) {
                                    //分组负责人审核的情况下，前端不需要显示，无需设置
                                    copyData.projectAuditorId = list[i].projectAuditorId;
                                    copyData.projectAuditorName = list[i].projectAuditorName;
                                }
                                console.log(copyData);
                                array.push(copyData);
                                if (list[i].state >= 2) {
                                    this.canEdit = true;
                                } else {
                                    this.canDeleteReport = false
                                }
                                if (list[i].state == 0) {
                                    this.canCancel = true;
                                    this.canCancelInDialog = true
                                }
                            }
                            this.form.domains = array;
                            // console.log('执行One')
                            this.setTotalReportHours()
                        } else {
                            // console.log('执行TWO')
                            this.totalReportHours = 0
                            this.canCancel = false;
                            this.canEdit = true;
                            var shuzhi = this.user.timeType.allday + ''
                            //没有填报的可以点击提交
                            this.form.domains = [{
                                id: null,
                                projectId: "",
                                projectName: "",
                                // workingTime: t.type==3?(t.allday).toFixed(1):"8.0",
                                workingTime: t.type == 3 ? (t.allday).toFixed(1) : shuzhi.indexOf('.') == '-1' ? shuzhi + '.0' : shuzhi,
                                content: "",
                                state: 2,
                                progress: 100,
                                isOvertime: false,
                                professionProgress: [],
                                multiWorktime: t.multiWorktime,
                                worktimeList: [{}],
                                canEdit: true,

                                auditorFirst: { name: '', id: '' },
                                auditorSec: { name: '', id: '' },
                                auditorThird: { name: '', id: '' },
                                ccUserid: { name: '', id: '' }
                            }]
                            if (this.isWeekend && this.user.timeType.lockWorktime != 1) {
                                this.$set(this.form.domains[0], 'isOvertime', true)
                                if (t.type != 2 && t.type != 0) {
                                    this.$set(this.form.domains[0], 'overtimeHours', t.allday.toFixed(0))
                                }
                            }
                            if (this.report.timeBasecostList.length != 0 && this.user.company.packageProject == 1) {
                                this.form.domains[0].basecostId = this.report.timeBasecostList[0].id;
                                this.form.domains[0].basecostName = this.report.timeBasecostList[0].name;
                            }
                            this.canEdit = true;

                            this.setTotalReportHours()
                        }
                    } else {
                        this.$toast.clear();
                        this.$toast.fail('获取失败:' + res.msg);
                    }
                    if (localStorage.getItem("formVal")) {
                        // console.log('触发了')
                        var obj = localStorage.getItem("formVal")
                        this.form = JSON.parse(obj)
                        localStorage.removeItem('formVal')
                    }
                }).catch(err => { this.$toast.clear(); });
        },

        // 改变时间
        changeTime(time) {
            this.form.createDate = this.format(new Date(time), "yyyy-MM-dd");
            this.currentDate = time;
            this.showPicker = false;
            this.getReport();
        },

        //点击选择审核人
        clickPickAuditor(i, item) {
            if (!item.canEdit) return;
            if (item.auditUserList.length <= 1) return;
            this.clickIndex = i;
            item.showPickerAuditor = true;
            this.$forceUpdate();
        },

        choseAuditor(value, index) {
            if (value) {
                this.form.domains[this.clickIndex].projectAuditorId = value.auditorId;
                this.form.domains[this.clickIndex].projectAuditorName = value.auditorName;
            }
            this.form.domains[this.clickIndex].showPickerAuditor = false;
            this.$forceUpdate();
        },

        //点击选择角色
        clickPickProjectRole(i, item) {
            if (!item.canEdit) return;
            this.clickIndex = i;
            item.showPickerRole = true;
            this.$forceUpdate();
        },

        //点击选择子项目
        clickPickSubProject(i, item) {
            if (!item.canEdit) return;
            this.clickIndex = i;
            item.showPickerSubProject = true;
            this.$forceUpdate();
        },
        clickPickCostId(i, item) {
            if (!item.canEdit) return;
            this.clickIndex = i;
            item.showPickerCostId = true;
            this.$forceUpdate();
        },

        clickPickTaskGroup(i, item) {
            if (!item.canEdit) return;
            this.clickIndex = i;
            item.showPickerTaskGroup = true;
            this.$forceUpdate();
        },
        clickPickStage(i, item) {
            if (!item.canEdit) return;
            this.clickIndex = i;
            item.showPickerStage = true;
            this.$forceUpdate();
        },
        selectDeptPopup(i, item) {
            this.clickIndex = i;
            this.showSelectDeptPopup = true;
            this.$forceUpdate();
        },
        selectedDeptId(item) {
            const { label, value } = item
            this.form.domains[this.clickIndex].userReportDeptName = label
            this.form.domains[this.clickIndex].reportTargetDeptId = value
            this.showSelectDeptPopup = false;
            this.$forceUpdate();
        },
        // 选择项目
        clickPicker(i, item) {
            if (!item.canEdit) return;
            this.clickIndex = i
            this.showPickerUserddp = true
        },
        clickPickers(i, item) {
            // console.log('wudulist',item.wuduList);
            if (!item.canEdit) return;

            this.clickIndex = i;
            // item.showPickDegree = true;
            this.$set(item, 'showPickDegree', true)
            // var proId = this.form.domains[this.clickIndex].projectId
            // this.dimension(proId)
        },
        //选择服务
        clickPickerService(i, item) {
            if (!item.canEdit) return;
            // console.log(i);
            this.clickIndex = i;
            // var proId = this.form.domains[this.clickIndex].projectId
            // this.getTaskList(proId)
            // console.log(this.form.domains[this.clickIndex].taskList);
            this.$toast.clear();
            if (!this.form.domains[this.clickIndex].serviceList || this.form.domains[this.clickIndex].serviceList.length == 0) {
                this.$toast.fail('当前任务暂无服务');
            } else {
                this.form.domains[this.clickIndex].showPickerService = true;
                this.$forceUpdate();
            }
        },
        choseService(value, index) {
            if (value) {
                this.form.domains[this.clickIndex].sapServiceId = value.id;
                this.form.domains[this.clickIndex].sapServiceName = value.serviceName;
            }
            this.form.domains[this.clickIndex].showPickerService = false;
            this.$forceUpdate();
        },

        //选择任务
        clickPickerTask(i, item) {
            if (!item.canEdit) return;
            this.clickIndex = i;
            this.$toast.clear();
            if (!this.form.domains[this.clickIndex].taskList || this.form.domains[this.clickIndex].taskList.length == 0) {
                this.$toast.fail('当前项目暂无可选的任务/里程碑');
            } else {
                this.form.domains[this.clickIndex].showPickerTask = true;
                this.$forceUpdate();
            }
        },
        choseTask(value, index) {
            if (value) {
                this.form.domains[this.clickIndex].taskId = value.taskId;
                this.form.domains[this.clickIndex].taskName = value.taskName;
            }
            this.form.domains[this.clickIndex].showPickerTask = false;
            this.$forceUpdate();
            if (this.user.companyId == 3092) {
                //依斯贝，加载服务
                this.$axios.post("/task/getMyTaskService", { taskId: value.taskId })
                    .then(res => {
                        if (res.code == "ok") {
                            this.$set(this.form.domains[this.clickIndex], 'sapServiceId', null);
                            this.$set(this.form.domains[this.clickIndex], 'sapServiceName', null);
                            this.$set(this.form.domains[this.clickIndex], 'serviceList', res.data);
                            this.$forceUpdate();
                        } else {
                            this.$toast.fail('获取失败:' + res.msg);
                        }
                    }).catch(err => { this.$toast.clear(); });
            }
        },
        choseProjects(value, index) {
            const { timeType } = this.user
            console.log(value, timeType)
            if (value) {
                if(!timeType.customDegreeMultiple) { // 单选
                    this.form.domains[this.clickIndex].weiduName = value.name;
                    this.form.domains[this.clickIndex].degreeId = value.id;
                }
                
                if(timeType.customDegreeMultiple) { // 多选
                    this.form.domains[this.clickIndex].weiduName = value.map(item => item.name).join(',');
                    this.form.domains[this.clickIndex].degreeId = value.map(item => item.id).join(',');
                }
                this.$forceUpdate();
            }
            this.form.domains[this.clickIndex].showPickDegree = false;
        },



        choseSubProject(value, index) {
            if (value) {
                this.form.domains[this.clickIndex].subProjectId = value.id;
                this.form.domains[this.clickIndex].subProjectName = value.name;
            }
            this.form.domains[this.clickIndex].showPickerSubProject = false;
            this.$forceUpdate();
        },

        choseTaskGroup(value, index) {
            if (!value) {
                return
            }
            this.form.domains[this.clickIndex].groupId = value.id;
            this.form.domains[this.clickIndex].groupName = value.name;
            this.form.domains[this.clickIndex].showPickerTaskGroup = false;
            var domainItem = this.form.domains[this.clickIndex];

            this.getGroupStages(this.form.domains[this.clickIndex], index);

            //检查当前的项目是否需要获取分组的负责人
            var curProject = this.project.filter(p => p.id == this.form.domains[this.clickIndex].projectId)[0];
            if (curProject && (this.user.timeType.reportAuditType == 1 || this.user.timeType.reportAuditType == 2)) {
                this.$axios.post("/task-group/getGroupIncharger", { groupId: domainItem.groupId })
                    .then(res => {
                        if (res.code == "ok") {
                            domainItem.auditUserList = res.data;
                            if (res.data.length == 1) {
                                domainItem.projectAuditorId = domainItem.auditUserList[0].auditorId;
                                domainItem.projectAuditorName = domainItem.auditUserList[0].auditorName;
                            }
                            this.$forceUpdate();
                        } else {
                            this.$toast.fail('获取失败:' + res.msg);
                        }
                    }).catch(err => { this.$toast.clear(); });
            }
        },
        choseStage(value, index) {
            if (value) {
                this.form.domains[this.clickIndex].stage = value.stagesName;
            }

            this.form.domains[this.clickIndex].showPickerStage = false;
            this.getTaskList(this.form.domains[this.clickIndex]);
            this.$forceUpdate();

        },
        choseCostId(value, index) {
            if (value) {
                this.form.domains[this.clickIndex].basecostId = value.id;
                this.form.domains[this.clickIndex].basecostName = value.name;
            }
            this.form.domains[this.clickIndex].showPickerCostId = false;
            this.$forceUpdate();
        },

        getGroupStages(domain, index) {
            this.$axios.post("/stages/getProjectStagesByGroup", { groupId: domain.groupId })
                .then(res => {
                    if (res.code == "ok") {
                        domain.stage = '';
                        domain.stages = res.data;
                        this.getTaskList(domain);
                        this.$forceUpdate();
                    } else {
                        this.$toast.fail('获取失败');
                    }
                }).catch(err => { this.$toast.clear(); });
        },


        getProjectProfessions(domain, index) {
            // console.log(domain, '数据')
            this.$axios.post("/project-profession/getMyProfession", { projectId: domain.projectId })
                .then(res => {
                    if (res.code == "ok") {
                        this.form.domains[this.clickIndex].professionProgress = res.data;
                        // console.log(this.form.domains)
                        this.$forceUpdate();
                    } else {
                        this.$toast.fail('获取失败');
                    }
                }).catch(err => { this.$toast.clear(); });
        },
        // 获取维度
        dimension(projectId) {
            this.$axios.post("/project/getDegreeList", { projectId: projectId })
                .then(res => {
                    if (res.code == "ok") {
                        this.form.domains[this.clickIndex].wuduList = res.data;
                        this.$forceUpdate();
                    } else {
                        this.$toast.fail('获取失败');
                    }
                }).catch(err => { this.$toast.clear(); });
        },


        getTaskList(domainItem) {
            //如果是专业版，需要列出任务列表
            if (this.user.company.packageProject == 1) {
                //清空选中的任务
                this.$set(domainItem, 'taskId', null);
                this.$set(domainItem, 'taskName', null);
                this.$set(domainItem, 'sapServiceId', null);
                this.$set(domainItem, 'sapServiceName', null);
                this.$set(domainItem, 'serviceList', null);
                var param = { projectId: domainItem.projectId }
                if (domainItem.groupId) {
                    param.groupId = domainItem.groupId;
                }
                if (domainItem.stage) {
                    param.stage = domainItem.stage;
                }
                this.$axios.post("/task/getRecentTask", param)
                    .then(res => {
                        if (res.code == "ok") {
                            this.form.domains[this.clickIndex].allTaskList = res.data;
                            this.form.domains[this.clickIndex].taskList = res.data;
                            this.form = this.form;
                        } else {
                            this.$toast.fail('获取失败');
                        }
                    }).catch(err => { this.$toast.clear(); });
            }
        },
        //添加工时
        addNewWorktime(index, item) {
            item.worktimeList.push({});
        },

        // 添加项目 
        addNewPro() {
            var leftProgress = 10;
            if (this.reportTimeType.type == 3) {
                //计算已经待分配工时比例
                let array = this.form.domains;
                let totalProgress = 0;
                for (var i = 0; i < array.length; i++) {
                    totalProgress += array[i].progress;
                }
                if (totalProgress < 100) {
                    leftProgress = 100 - totalProgress;
                }
            }
            let item = {
                id: null,
                projectId: "",
                projectName: "",
                workingTime: this.reportTimeType.type == 3 ? (leftProgress * this.reportTimeType.allday / 100).toFixed(1) : "4.0",
                progress: leftProgress,
                content: "",
                state: 2,
                isOvertime: false,
                multiWorktime: this.reportTimeType.multiWorktime,
                worktimeList: [{}],
                degreeId: null,
                canEdit: true
            }
            if (this.isWeekend && this.user.timeType.lockWorktime != 1) {
                this.$set(item, 'isOvertime', true)
                if (this.reportTimeType.type == 3) {
                    this.$set(item, 'overtimeHours', (leftProgress * this.reportTimeType.allday / 100).toFixed(1))
                } else {
                    this.$set(item, 'overtimeHours', 4)
                }
            }
            if (this.report.timeBasecostList.length != 0 && this.user.company.packageProject == 1) {
                this.$set(item, 'basecostId', this.report.timeBasecostList[0].id)
                this.$set(item, 'basecostName', this.report.timeBasecostList[0].name)
            }

            this.form.domains.push(item)
            //重新计算总时长
            this.setTotalReportHours();
            this.canEdit = true
            this.$forceUpdate()
        },

        // 移除项目
        delPro(i) {
            this.$dialog.confirm({
                title: '',
                message: '是否移除当前项目'
            }).then(() => {
                this.form.domains.splice(i, 1);
                //重新计算总时长
                this.setTotalReportHours();
            }).catch(() => {
            });
        },
        // 判断时间段
        fns() {
            for (let k in this.dateAr) {
                if (!this.judege(k)) {
                    return false
                }
            }
            return true
        },
        judege(idx) {
            for (let k in this.dateAr) {
                if (idx !== k) {
                    if (this.dateAr[k].s <= this.dateAr[idx].s && this.dateAr[k].e > this.dateAr[idx].s) {
                        return false
                    }
                    if (this.dateAr[k].s < this.dateAr[idx].e && this.dateAr[k].e >= this.dateAr[idx].e) {
                        return false
                    }
                }
            }
            return true
        },
        // 提交日报
        submitReport() {
            this.$refs.loginForm.validate().then(
                () => {
                    // 验证通过
                    if (this.isDraft == 1) {
                        //暂存操作
                        this.submitReportSon()
                    } else {
                        if (this.user.companyId == 5978) {
                            //调接口，先检查工时是否存在异常
                            var totalTime = 0;
                            for (var t=0;t<this.form.domains.length; t++) {
                                totalTime += parseFloat(this.form.domains[t].workingTime);
                            }
                            var array = [];
                            var workData = {date: this.form.createDate, workingTime: totalTime};
                            array.push(workData)
                            this.flgLg = false
                            var passUserId = this.user.id;
                            this.$axios.post("/report/checkBeiSengCardTime", { userId:passUserId, dateWorkingTime:JSON.stringify(array)})
                            .then(res => {
                                this.flgLg = true
                                if (res.code == "ok") {
                                    this.submitReportSon()
                                } else {
                                    this.$dialog.confirm({
                                        title: '提示',
                                        message: res.msg + '，是否忽略并提交?'
                                    }).then(() => {
                                        this.submitReportSon()
                                    }).catch(() => { })
                                }
                            }).catch(err => { this.$toast.clear(); });
                        } else {
                            //提交时，做工时时长不足的提醒
                            if (this.totalReportHours < this.user.timeType.allday) {
                                this.$dialog.confirm({
                                    title: '提示',
                                    message: '当日工时不足' + this.user.timeType.allday.toFixed(1) + '小时，是否确定提交?'
                                }).then(() => {
                                    this.submitReportSon()
                                }).catch(() => { })
                            } else {
                                this.submitReportSon()
                            }
                        }
                    }
                }).catch((err) => {
                    console.log('验证失败', err);
                    return
                })

        },
        submitReportSon() {
            this.dateAr = []
            let alp = []
            if (this.user.timeType.multiWorktime == 1) {
                for (var p in this.form.domains) {
                    var slll = this.form.domains[p]
                    for (var o in slll.worktimeList) {
                        if (slll.worktimeList[o].startTime || slll.worktimeList[o].endTime) {
                            let objs = {}
                            objs.s = slll.worktimeList[o].startTime
                            objs.e = slll.worktimeList[o].endTime
                            alp.push(objs)
                        }
                    }
                }
            } else {
                for (let i in this.form.domains) {
                    let objs = {}
                    objs.s = this.form.domains[i].startTime
                    objs.e = this.form.domains[i].endTime
                    alp.push(objs)
                }
            }
            this.dateAr = alp
            console.log(this.reportTimeType, this.dateAr, '<=== this.reportTimeType')

            const { timeCanOverlap } = this.reportTimeType // timeCanOverlap 为零时校验不允许重叠
            if (this.dateAr.length > 0 && timeCanOverlap == 0) {
                let trus = this.fns()
                if (!trus) {
                    this.$toast('填写的时间段重叠')
                    return
                }
            }

            //检查子项目
            if (this.user.timeType.subProMustFill == 1) {
                for (var p in this.form.domains) {
                    var oneProItem = this.form.domains[p]
                    if (oneProItem.subProjectList.length > 0 && !oneProItem.subProjectId) {
                        //没有选择子项目
                        this.$toast('子项目必填，请检查')
                        return
                    }
                }
            }
            //昱众需要检查新增的角色，工作职责，工作内容是否填写
            if (this.user.companyId == this.yuzhongCompId) {
                for (var p in this.form.domains) {
                    var oneProItem = this.form.domains[p]
                    if (!oneProItem.extraField1) {
                        this.$toast('角色必填，请检查')
                        return
                    }
                    if (!oneProItem.extraField2) {
                        this.$toast('工作职责必填，请检查')
                        return
                    }
                    if (!oneProItem.extraField3) {
                        this.$toast('工作内容必填，请检查')
                        return
                    }
                }
            }

            // return
            const toast = this.$toast.loading({
                forbidClick: true,
                duration: 0
            });
            // let formData = new FormData();
            let formData = new URLSearchParams()
            formData.append("draft", this.isDraft);
            if (this.reportTimeType.type == 0) {
                var alldayNum = 0;
                var amNum = 0;
                var pmNum = 0;
                for (var i in this.form.domains) {
                    if (this.form.domains[i].timeType == 0) {
                        alldayNum++;
                    } else if (this.form.domains[i].timeType == 1) {
                        amNum++;
                    } else if (this.form.domains[i].timeType == 2) {
                        pmNum++;
                    }
                }
                if (alldayNum > 1) {
                    this.$toast.fail("工作时间-全天，只能选择一次");
                    return;
                }
                if (amNum > 1) {
                    this.$toast.fail("工作时间-上午，只能选择一次");

                    return;
                }
                if (pmNum > 1) {
                    this.$toast.fail("工作时间-下午，只能选择一次");
                    return;
                }
                if (alldayNum == 1 && (amNum > 0 || pmNum > 0)) {
                    this.$toast.fail("工作时间-全天，不能和上下午同时存在");
                    return;
                }
            } else if (this.reportTimeType.type == 3) {
                //总百分比不能超过100%
                let total = 0;
                this.form.domains.forEach(w => { total += w.progress });
                if (total > 100) {
                    this.$toast.fail("用时比例之和不能超过100%");
                    return;
                } else if (total < 100) {
                    this.$toast.fail("工时尚未完全分配，无法提交");
                    return;
                }
            }
            if (this.user.timeType.notAllowedNoAttendance == 1 && this.isDraft == 0) {
                if (this.report.time) {
                    if (this.report.time.workHours == 0) {
                        this.$toast.fail('无考勤记录不可填报')
                        return
                    }
                } else {
                    this.$toast.fail('无考勤记录不可填报')
                    return
                }
            }
            //针对凡己，不校验考勤时长
            if (this.user.companyId != 3918 && this.user.companyId != 5978 && this.user.companyId != 4281 && this.reportTimeType.type == 1 && this.report.time) {
                var totalTime = 0;
                for (var t = 0; t < this.form.domains.length; t++) {
                    totalTime += parseFloat(this.form.domains[t].workingTime);
                }
                if (this.report.time.workHours && totalTime > parseFloat(this.report.time.workHours)) {
                    this.$toast.fail("填报工时之和" + (totalTime) + "h不能超过考勤总工时(" + this.report.time.workHours.toFixed(1) + "h)");
                    return;
                }
            }
            //填字段
            for (var i in this.form.domains) {
                // 检查任务/里程碑是否为必填
                let taskRequired = this.user.timeType.taskRequired
                if(taskRequired == 1) {
                    if(!this.form.domains[i].taskId) {
                        this.$toast.fail(`【${this.form.domains[i].projectName}】请选择 任务/里程碑`);
                        return
                    }
                }

                if (this.user.timeType.reportAuditType == 3) {
                    let audItem = {}
                    let auditRule = ''
                    if (this.form.domains[i].id) {
                        audItem.reportId = this.form.domains[i].id
                    }
                    if (this.user.timeType.auditLevel > 0) {
                        if (!this.form.domains[i].auditorFirst.id) { auditRule += '第一、' }
                        audItem.auditorFirst = this.form.domains[i].auditorFirst.id
                    }
                    if (this.user.timeType.auditLevel > 1) {
                        // if(!this.form.domains[i].auditorSec.id){ auditRule += '第二、' }
                        audItem.auditorSec = this.form.domains[i].auditorSec.id
                    }
                    if (this.user.timeType.auditLevel > 2) {
                        // if(!this.form.domains[i].auditorThird.id){ auditRule += '第三、' }
                        audItem.auditorThird = this.form.domains[i].auditorThird.id
                    }
                    if (this.form.domains[i].ccUserid.id) {
                        audItem.ccUserid = this.form.domains[i].ccUserid.id
                    }
                    if (auditRule) {
                        auditRule = auditRule.substring(0, auditRule.length - 1)
                        this.$toast.fail('请指定' + auditRule + '审核人')
                        return
                    }
                    formData.append("auditorSettingArray", JSON.stringify(audItem).replace(/,/g, "@"));
                }

                if (this.user.timeType.customDegreeActive == 1) {
                    let customDegreeMultiple = this.user.timeType.customDegreeMultiple
                    if (this.form.domains[i].degreeId) {
                        if(!customDegreeMultiple) { // 单选
                            formData.append("degreeId", this.form.domains[i].degreeId);
                        } else {
                            let newDegreeId = this.form.domains[i].degreeId.split(',')
                            formData.append("multiDegrId", JSON.stringify(newDegreeId).replace(/,/g, '@'))
                        }
                    } else {
                        if (this.user.timeType.customDegreeStatus && !this.form.domains[i].degreeId) {
                            this.$toast.fail('请选择' + this.user.timeType.customDegreeName)
                            return
                        } else {
                            if (!customDegreeMultiple) {
                                formData.append("degreeId", -1);
                            } else {
                                formData.append("multiDegrId", '[]');
                            }
                        }
                    }
                } else {
                    formData.append("degreeId", -1);
                }
                if (this.form.domains[i].customData) {
                    formData.append("customData", this.form.domains[i].customData);
                } else {
                    formData.append("customData", "0");
                }
                if (this.form.domains[i].customText) {
                    formData.append("customText", this.form.domains[i].customText);
                } else {
                    formData.append("customText", "-");
                }

                if (this.form.domains[i].id) {
                    formData.append("id", this.form.domains[i].id);
                } else {
                    formData.append("id", -1);
                }
                formData.append("projectId", parseFloat(this.form.domains[i].projectId));
                if (this.form.domains[i].subProjectId) {
                    formData.append("subProjectId", this.form.domains[i].subProjectId);
                } else {
                    formData.append("subProjectId", 0);
                }
                if (this.form.domains[i].groupId) {
                    formData.append("groupId", this.form.domains[i].groupId);
                } else {
                    formData.append("groupId", 0);
                }
                if (this.form.domains[i].stage) {
                    formData.append("stage", this.form.domains[i].stage);
                } else {
                    formData.append("stage", '-');
                }
                formData.append("reportTimeType", this.reportTimeType.type);
                formData.append("multiWorktime", this.reportTimeType.multiWorktime);
                if (this.reportTimeType.type == 0) {
                    formData.append("timeType", this.form.domains[i].timeType);
                    formData.append("workingTime", parseFloat(this.form.domains[i].workingTime));
                } else if (this.reportTimeType.type == 1) {
                    formData.append("workingTime", parseFloat(this.form.domains[i].workingTime));
                } else if (this.reportTimeType.type == 2) {
                    formData.append("startTime", this.form.domains[i].startTime);
                    formData.append("endTime", this.form.domains[i].endTime);
                } else if (this.reportTimeType.type == 3) {
                    formData.append("progress", this.form.domains[i].progress);
                    formData.append("workingTime", this.form.domains[i].workingTime);
                }

                if (this.form.domains[i].taskId) {
                    formData.append("taskId", this.form.domains[i].taskId);
                } else {
                    formData.append("taskId", 0);
                }
                if (this.form.domains[i].taskFinish == true) {
                    formData.append("taskFinish", 1);
                } else {
                    formData.append("taskFinish", 0);
                }
                // formData.append("createDate", this.form.createDate);
                if(this.substitute) {
                    formData.append("createDate", `${this.form.createDate}@${this.form.createDate}`);
                    formData.append("targetUids", this.fillingAgent.id || '');
                } else {
                    formData.append("createDate", this.form.createDate);
                }

                formData.append("isOvertime", this.form.domains[i].isOvertime && this.user.timeType.fillOvertime ? 1 : 0);
                if (this.form.domains[i].overtimeHours && this.form.domains[i].isOvertime == 1 && this.user.timeType.fillOvertime) {
                    formData.append("overtimeHours", this.form.domains[i].overtimeHours);
                } else {
                    formData.append("overtimeHours", 0);
                }
                if (this.form.domains[i].basecostId) {
                    formData.append("basecostId", this.form.domains[i].basecostId);
                }



                //项目专业进度
                if (this.form.domains[i].professionProgress) {
                    let m = JSON.stringify(this.form.domains[i].professionProgress);
                    m = m.replace(/,/g, "@");//replaceAll(',','@');企业微信不兼容replaceAll
                    formData.append("professionProgress", m);
                } else {
                    formData.append("professionProgress", "[]");
                }
                //图片的处理，serverPic是当前所有的照片
                if (this.form.domains[i].serverPics) {
                    let m = JSON.stringify(this.form.domains[i].serverPics);
                    m = m.replace(/,/g, "@");//replaceAll(',','@');企业微信不兼容replaceAll
                    formData.append("pics", m);
                } else {
                    formData.append("pics", "@");
                }

                //处理多个时间事项
                if (this.reportTimeType.multiWorktime == 1) {
                    //检查时间是否有重叠
                    var workList = this.form.domains[i].worktimeList;
                    for (var j = 0; j < workList.length; j++) {
                        var curItem = workList[j];
                        if (curItem.startTime == null || curItem.endTime == null) {
                            this.$toast.fail("请设置工作时间");
                            return;
                        }
                        //检查开始时间是否大于结束时间
                        if (curItem.startTime >= curItem.endTime) {
                            this.$toast.fail("时间段" + curItem.startTime + '-' + curItem.endTime + "有误：" +
                                "结束时间必须大于开始时间");
                            return;
                        }
                        //时间段不可重叠
                        if (this.reportTimeType.timeCanOverlap == 0) {
                            for (var p = j + 1; p < workList.length; p++) {
                                var jItem = workList[p];
                                if ((jItem.startTime >= curItem.startTime && jItem.startTime < curItem.endTime)
                                    || (jItem.endTime > curItem.startTime && jItem.endTime <= curItem.endTime)) {

                                    this.$toast.fail("时间段" + curItem.startTime + '-' + curItem.endTime + "与" +
                                        jItem.startTime + '-' + jItem.endTime + "存在重叠，请修改。");
                                    return;
                                }
                            }
                        }
                    }

                    let m = JSON.stringify(this.form.domains[i].worktimeList);
                    m = m.replace(/,/g, "@");//replaceAll(',','@');企业微信不兼容replaceAll
                    formData.append("content", m);
                } else {
                    if (this.form.domains[i].content) {
                        formData.append("content", this.form.domains[i].content);
                    } else {
                        formData.append("content", '-');
                    }
                }

                //审核人
                if (this.form.domains[i].projectAuditorId) {
                    formData.append("projectAuditorId", this.form.domains[i].projectAuditorId);
                } else {
                    if (this.user.timeType.reportAuditType == 0) {
                        this.$toast.fail("请指定[" + this.form.domains[i].projectName + ']项目的审核人');
                        return;
                    } else if (this.user.timeType.reportAuditType == 1 || this.user.timeType.reportAuditType == 2 ||  this.user.timeType.reportAuditType == 9) {
                        if (this.form.domains[i].taskGroups.length == 0) {
                            this.$toast.fail("您在[" + this.form.domains[i].projectName + "]项目上尚无参与的任务分组");
                            return;
                        } else if (!this.form.domains[i].groupId) {
                            this.$toast.fail("请选择[" + this.form.domains[i].projectName + "]项目的任务分组");
                            return;
                        }
                    }
                }
                if (this.user.companyId == this.yuzhongCompId) {
                    formData.append('extraField1', this.form.domains[i].extraField1);
                    formData.append('extraField2', this.form.domains[i].extraField2);
                    formData.append('extraField3', this.form.domains[i].extraField3);
                }
                if (this.user.companyId == 3092) {
                    formData.append('sapServiceId', this.form.domains[i].sapServiceId);
                }

                if(this.user.timeType.taskGroupRequired == 1 && !this.form.domains[i].groupId) {
                    this.$toast.fail("请选择[" + this.form.domains[i].projectName + "]项目的任务分组");
                    return;
                }

                if(this.user.timeType.userWithMultiDept == 1 && this.userReportDeptList.length > 0) {
                    if(this.form.domains[i].reportTargetDeptId) {
                        formData.append('reportTargetDeptId', this.form.domains[i].reportTargetDeptId || -1);
                    } else {
                        this.$toast.fail("请选择[" + this.form.domains[i].projectName + "]填报部门");
                        return;
                    }
                }
            }
            if (!this.flgLg) {
                return
            }
            this.flgLg = false
            this.$axios.post("/report/editReport", formData)
                .then(res => {
                    this.flgLg = true
                    if (res.code == "ok") {
                        this.$toast.clear();
                        if (res.msg != null) {
                            this.$dialog.alert({
                                message: res.msg,
                            }).then(() => {
                                // on close
                                this.$router.push("/index");
                            });
                        } else {
                            this.$toast.success(this.isDraft == 0 ? '提交成功' : '暂存成功');
                            setTimeout(() => {
                                this.$router.push("/index");
                            }, 500);
                        }
                    } else {
                        this.$toast.clear();
                        this.$toast.fail((this.isDraft == 0 ? '提交失败' : '暂存失败') + '：' + res.msg);
                    }
                }).catch(err => { this.$toast.clear(); this.flgLg = true });
        },
        //初始化参数
        initWxConfig() {
            var curUrl = location.href.split("#")[0];

            this.$axios.post("/wxcorp/getCorpWXConfig", { url: curUrl, token: this.user.id })
                .then(res => {
                    if (res.code == "ok") {
                        var data = res.data;
                        wx.config({
                            beta: true,
                            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                            appId: data.appid, // 必填，公众号的唯一标识 
                            timestamp: data.timestamp, // 必填，生成签名的时间戳 
                            nonceStr: data.noncestr, // 必填，生成签名的随机串 
                            signature: data.sign, // 必填，签名，见附录1 
                            jsApiList: [
                                'chooseImage',
                                'previewImage',
                                'uploadImage',
                                'downloadImage',
                                'previewFile',
                                'getLocation',
                            ]
                        });
                        var that = this;
                        wx.ready(function () {
                            // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
                            // if (that.user.companyId == 7) {
                            //     that.$toast('wx init success==7 ');
                            //     that.getLocation();
                            // }
                        });
                    } else {
                        this.$toast.fail('获取失败');
                    }
                }).catch(err => { this.$toast.fail('发生异常' + err); console.log(err) });

        },
        deleteImg(item, index) {
            //删除添加的图片
            if (this.isIOSystem) {
                item.iospics.splice(index, 1);
                item.iospics = item.iospics;
            }
            item.pics.splice(index, 1);
            item.pics = item.pics;

            //传递到后台的数据也要做同步删除
            item.serverPics.splice(index, 1);
            this.$forceUpdate();
        },
        //企业微信获取定位
        getLocation() {
            wx.getLocation({
                type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
                success: function (res) {
                    var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
                    var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
                    alert(JSON.stringify(res));
                }
            });
        },
        // 获取可选部门
        getReportDeptList() {
            this.$axios.post("/user/getUserInfo", {userId: this.user.id})
            .then(res => {
                if(res.code == "ok") {
                    let lists = res.data.userReportDeptList || []
                    const optimizedList = lists.map(item => ({
                        ...item,
                        label: item.departmentName,
                        value: item.departmentId,
                    }));
                    this.userReportDeptList = optimizedList
                    console.log(this.userReportDeptList, '<==== 返回的数据')
                } else {
                    this.$toast.fail('获取失败:'+res.msg);
                }
            }).catch(err=> {this.$toast.clear();});
        }
    },

    mounted() {
        const { substitute } = this.$route.query;
        if (substitute) {
            this.substitute = true;
        }

        this.today = this.format(new Date(), 'yyyy-MM-dd')
        this.timeRange = []
        let i = 0.0; 
        while(true) {
            if (i < 48) {
                //校验上下限
                if (i >= this.user.timeType.minReportTime && i <= this.user.timeType.maxReportTime) {
                    this.timeRange.push(i);
                }
                i += 0.5;
            } else {
                break;
            }
        }
        // console.log('user',this.user)
        var ua = navigator.userAgent.toLowerCase();
        this.isIOSystem = this.isIOS();
        if (ua.indexOf("wxwork") > 0) {
            this.isCorpWX = true;
        } else if (ua.indexOf("micromessenger") > 0) {
            this.isWX = true;
        }
        this.getPeoject() // 获取项目
        // console.log('mounted');
        this.getReportBasecostList()
        //获取传递过来的日期
        var passDate = this.$route.query.date;
        if (passDate != null) {
            this.form.createDate = this.$route.query.date;
        }

        // this.getProject();
        this.getReport();
        this.getAllUsersList()
        this.getTimeType();

        //初始化微信js-sdk参数
        if (this.isCorpWX) {
            this.initWxConfig();
        }
        if (this.user.timeType.lockWorktime) {
            this.timeRange = this.timeRange.filter(item => { return item <= this.user.timeType.allday })
        }
        if (this.user.companyId == this.yuzhongCompId) {
            this.getJobResponList();
        }

        if(this.user.timeType.userWithMultiDept) {
            this.getReportDeptList()
        }

        // if(localStorage.getItem("formVal")) {
        //     var obj = localStorage.getItem("formVal")
        //     this.form = JSON.parse(obj)
        //     localStorage.removeItem('formVal')
        // }
    },
    beforeDestroy() {
        localStorage.removeItem('formVal')
    }
};
</script>

<style lang="less" scope>
.groupingSelection {
    height: 100%;
    overflow-y: auto;
    padding: 10px 0;
    .groupingSelectionItem { 
        padding: 15px 15px;
        border-bottom: 1px solid #c2c2c2;
    }
}
.chooseSomeone {
    height: 80vh;
    display: flex;
    flex-direction: column;
    .chooseSomeoneFlex1 {
        flex: 1;
        overflow: auto;
    }
    .chooseSomeoneBtn {
        width:100%;
    }
}
.my-swipe .van-swipe-item {
    color: #fff;
    font-size: 20px;
    line-height: 150px;
    text-align: center;
    background-color: #39a9ed;

}

.van-field__control:disabled {
    -webkit-text-fill-color: #646566;
}

.login_form {
    margin-top: 46px;
    position: relative;
}

.attendanceRecord {
    // position: absolute;
    // top: 0.26667rem;
    // right: 0.42667rem;
    color: #969799;
    text-align: center;
    font-size: 0.35rem;
    line-height: 0.64rem;
}

.form_domains {
    position: relative;

    .form_copy {
        position: absolute;
        right: 85px;
        top: 10px;
    }

    .form_addNew {
        text-align: center;
        margin: 10px;
    }

    .form_del {
        position: absolute;
        right: 10px;
        top: 10px;
    }

    // .form_del {
    //     color: #ff0000;
    //     font-size: 22px;
    //     position: absolute;
    //     right: 15px;
    //     top: 10px;
    // }
}

.form_tip {
    text-align: center;
    margin-top: 20px;
    color: #afafaf;
    font-size: 14px;
}

.card__footer {
    padding-top: 10px;
}

.card__tags {
    .van-tag {
        margin-right: 5px;
    }
}

.overTimeClas {
    display: flex;
    width: 7.4rem;
    // justify-content: space-between;
    align-items: center;
}
</style>
<style lang="less">
.van-nav-bar .van-icon,
.van-nav-bar__text {
    color: #20a0ff;
}

.overtime {
    height: 50px;
    box-sizing: border-box;
    padding-left: 10px;
    display: flex;
    align-items: center;
}

.custom-button {
    width: 40px;
    color: #fff;
    font-size: 10px;
    line-height: 16px;
    text-align: center;
    background-color: #1989fa;
    border-radius: 100px;
}

.imgList {
    position: relative;
    width: 100px;
    height: 100px;
    display: inline-block;
}

.imgList_i {
    position: absolute;
    top: -8px;
    right: -6px;
    z-index: 999;
}

.ryuan {
    padding: 0.3rem 0.53333rem;
    // line-height: 40px;
    // height: 40px;
    overflow: auto;
    color: #333;
    border-bottom: 2px solid #f6f6f6;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.overListTime {
    margin-left: 5px;
    display: inline-block;
    width: 2rem;
    color: #646566;
}

.hoveOver {
    color: #C8C9CC;
}

.van-checkbox__label {
    color: #646566 !important;
}

.van-checkbox__label--disabled {
    color: #c8c9cc !important;
}
</style>


